import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { EXPORT_PROJECT_JOBS } from "@lango/common/queries";
import { prepareExportProjectJobsRequest } from "../request";

const useExportProjectJobs = () => {
  const [exportProjectJob, { loading, error }] =
    useMutation(EXPORT_PROJECT_JOBS);

  const handleExportProjectJob = useCallback(
    async (projectID, orgID, appName, filters, onSuccess, onError) => {
      try {
        const res = await exportProjectJob(
          prepareExportProjectJobsRequest(projectID, orgID, appName, filters),
        );
        const result = res?.data?.exportProjectJobs;
        onSuccess && onSuccess(result);
      } catch (err) {
        onError && onError(err);
      }
    },
    [exportProjectJob],
  );

  return { handleExportProjectJob, loading, error };
};

export default useExportProjectJobs;
