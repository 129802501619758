import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import env from "@lango/common/env";

import { appRedirectState } from "../apollo";
import PageLoader from "../components/page-loader";
import { useNavigate } from "react-router-dom";

const { auth0Domain, auth0ClientID, auth0Audience } = env;

function Auth0LoadingWrapper({ children }) {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
}

/**
 * Auth0 Provider component that wraps the application with Auth0 authentication.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the Auth0Provider.
 *
 * @throws {Error} Throws an error if auth0Domain or auth0ClientID are not set in the environment variables.
 *
 * @returns {JSX.Element} The Auth0Provider component wrapping the children components.
 *
 * @example
 * <Provider>
 *   <App />
 * </Provider>
 */
const Provider = ({ children }) => {
  const navigate = useNavigate();
  if (!auth0Domain || !auth0ClientID || !auth0Audience)
    throw new Error(
      "Please set auth0 domain and clientId in the environment variables",
    );

  function handleRedirectCallback(
    /** @type {import("@auth0/auth0-react").AppState} */ appState,
  ) {
    if (appState?.code && appState?.type) {
      navigate(`/invitation/${appState.type}/${appState.code}`);
      return;
    }
    appRedirectState({ ...appState, initializeUser: true });
    navigate("/");
  }

  return (
    <Auth0Provider
      useRefreshTokens
      domain={auth0Domain}
      clientId={auth0ClientID}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: "openid profile email offline_access",
        audience: auth0Audience,
      }}
      onRedirectCallback={handleRedirectCallback}
    >
      <Auth0LoadingWrapper>{children}</Auth0LoadingWrapper>
    </Auth0Provider>
  );
};

export default Provider;
