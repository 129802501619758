import { gql } from "@apollo/client";
import { CURRENT_USER } from "../fragments/user";

export const CAN_EDIT_PROJECT = gql`
  query CanEditProject($projectID: ID!, $organizationID: ID!) {
    canEditProject(organizationID: $organizationID, projectID: $projectID)
  }
`;

export const CAN_EDIT_JOB = gql`
  query CanEditJob($jobID: ID!, $organizationID: ID!) {
    canEditJob(organizationID: $organizationID, jobID: $jobID)
  }
`;

export const UPDATE_USER_SETTINGS = gql`
  ${CURRENT_USER}
  mutation UpdateUserSettings($input: UpdateUserSettingsRequest!) {
    updateUserSettings(input: $input) {
      ...CurrentUserFields
    }
  }
`;

export const USER_PROFILE_PICTURE = {
  UPLOAD: gql`
    mutation uploadUserPicture($file: Upload!) {
      uploadUserPicture(file: $file) {
        id
        picture {
          url
        }
      }
    }
  `,
  DELETE: gql`
    mutation deleteUserPicture {
      deleteUserPicture {
        id
        picture {
          url
        }
      }
    }
  `,
};

export const INITIALZE_USER = gql`
  mutation InitializeUser($input: InitializeUserRequest!) {
    initializeUser(input: $input) {
      id
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($input: AcceptInvitationRequest!) {
    acceptInvitation(input: $input) {
      organizationID
    }
  }
`;
