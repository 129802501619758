import {
  ASSIGNED_USER_INFO,
  JOB_LANGUAGES,
  JOB_TYPE,
  JOB_USER,
  LANGUAGE_FIELDS,
  TASK_LANGUAGES,
} from "@lango/common/fragments";
import { VENDOR_PROFILE_DETAILS } from "@lango/common/fragments/organizations";

import { gql } from "@apollo/client";

export const PAGINATED_VENDOR_TASK = gql`
  ${TASK_LANGUAGES}
  query paginatedVendorTask($input: PaginatedVendorUserTasksRequest!) {
    paginatedVendorUserTask(input: $input) {
      tasks {
        id
        taskType {
          id
          name
        }
        taskStatus {
          id
          name
        }
        ...taskLanguages
        createdAt
      }
      totalRecords
    }
  }
`;

export const PAGINATED_VENDOR_JOBS = gql`
  ${JOB_LANGUAGES}
  query paginatedVendorJobs($input: PaginatedJobsRequest!) {
    paginatedJobs(input: $input) {
      jobs {
        id
        jobType {
          id
          name
        }
        jobStatus {
          id
          name
        }
        ...jobLanguages
        createdAt
      }
      totalRecords
    }
  }
`;

export const VENDOR_JOB_DETAILS = gql`
  ${JOB_TYPE}
  ${LANGUAGE_FIELDS}
  ${JOB_USER}
  ${ASSIGNED_USER_INFO}
  query vendorJobDetails($jobID: ID!) {
    vendorJobDetails(jobID: $jobID) {
      id
      createdByUser {
        ...jobUser
      }
      jobType {
        ...JobType
      }
      ...LanguagesFields
      createdAt
      tasks {
        id
        assignedUser {
          ...assignedUserInfo
        }
        organization {
          id
          name
        }
        gender {
          id
          name
        }
        startTime
        endTime
        activeCallDuration
      }
    }
  }
`;

export const FIND_LINGUIST_PROFILE = gql`
  query findLinguistProfile($userID: ID!, $vendorID: ID!) {
    findLinguistProfile(userID: $userID, vendorID: $vendorID) {
      id
      isActive
      user {
        id
        firstName
        lastName
      }
      linguistUniqueID
    }
  }
`;

export const ALL_LANGUAGES_AND_JOB_TYPES = gql`
  query allLanguagesAndAllJobTypes($types: [String!]) {
    allLanguages {
      value: id
      label: name
    }
    allJobTypes(types: $types) {
      value: id
      label: name
    }
  }
`;

export const ON_DEMAND_JOB_ENABLED = gql`
  query isOnDemandJobEnabled($vendorID: ID!) {
    isOnDemandJobEnabled(vendorID: $vendorID)
  }
`;

export const ALL_ORG_VENDORS = gql`
  query allOrgVendors($input: GetAllOrgVendorsRequest!) {
    allOrgVendors(input: $input) {
      vendorProfiles {
        organizationID
        id
        name
        firstName
        lastName
        vendor {
          value: id
          label: name
        }
        vendorProfileType {
          id
          name
        }
      }
      totalRecords
    }
  }
`;

export const GET_VENDOR_PROFILE = gql`
  ${VENDOR_PROFILE_DETAILS}
  query FindVendorProfile($id: ID!) {
    findVendorProfile(id: $id) {
      ...vendorProfileDetails
      pendingActions {
        type
        title
        description
      }
    }
  }
`;

export const GET_VENDOR_PROFILE_JOB_TYPES = gql`
  query vendorProfileJobTypes($vendorProfileID: ID!) {
    findVendorProfileJobTypes(vendorProfileID: $vendorProfileID) {
      id
      jobType {
        value: id
        label: name
        type
      }
    }
  }
`;

export const GET_LSP_ORG_JOB_TYPES = gql`
  query lspOrgJobTypes($organizationID: ID!, $lspID: ID!, $types: [String!]) {
    findVendorProfileJobTypesByLspID(
      organizationID: $organizationID
      lspID: $lspID
    ) {
      id
      jobType {
        value: id
        label: name
        type
      }
    }

    allJobTypes(types: $types) {
      id
      name
    }
  }
`;

// updates a single job type by organization and LSP
export const UPDATE_VENDOR_PROFILE_JOB_TYPE = gql`
  mutation UpdateVendorProfileJobType(
    $input: UpdateVendorProfileJobTypeRequest!
  ) {
    updateVendorProfileJobType(input: $input)
  }
`;

// updates multiple job types by vendor profile ID
export const UPDATE_VENDOR_PROFILE_JOB_TYPES = gql`
  mutation UpdateVendorProfileJobTypes(
    $input: UpdateVendorProfileJobTypesRequest!
  ) {
    updateVendorProfileJobTypes(input: $input)
  }
`;

export const GET_CLIENT_PROFILE = gql`
  query FindClientProfile($id: ID!) {
    findClientProfile(id: $id) {
      id
      name
      status
      clientPreferences
      website
      description
      organizationID
      lspID
    }
  }
`;

export const NEW_VENDOR_PROFILE = gql`
  mutation CreateVendorProfileRequest($input: CreateVendorProfileRequest!) {
    createVendorProfile(input: $input)
  }
`;

export const ALL_INDUSTRIES = gql`
  query allIndustries {
    allIndustries {
      value: id
      label: name
    }
  }
`;

export const CREATE_VENDOR_PROFILE_LANGUAGE = gql`
  mutation createVendorProfileLanguage($request: VendorProfileLanguagePair!) {
    createVendorProfileLanguage(input: $request)
  }
`;

export const ALL_VENDOR_PROFILE_LANGUAGE = gql`
  query paginatedVendorProfileLanguage(
    $organizationID: ID!
    $lspID: ID!
    $offset: Int!
    $pageLimit: Int!
  ) {
    paginatedVendorProfileLanguage(
      organizationID: $organizationID
      lspID: $lspID
      pagination: { offset: $offset, pageLimit: $pageLimit }
    ) {
      vendorProfileLanguages {
        id
        languageFrom {
          label: displayName
          value: id
        }
        languageTo {
          label: displayName
          value: id
        }
        industry {
          label: name
          value: id
        }
        jobType {
          label: name
          value: id
          type
        }
        qualificationStatus
      }
      totalRecords
    }
  }
`;

export const DELETE_VENDOR_PROFILE_LANGUAGE = gql`
  mutation deleteVendorProfileLanguage(
    $organizationID: ID!
    $vendorProfileLangaugeID: ID!
  ) {
    deleteVendorProfileLanguage(
      organizationID: $organizationID
      vendorProfileLangaugeID: $vendorProfileLangaugeID
    )
  }
`;

export const UPDATE_VENDOR_PROFILE_LANGUAGE_PAIR = gql`
  mutation updateVendorProfileLanguagePair(
    $request: UpdateVendorProfileLanguageRequest!
  ) {
    updateVendorProfileLanguagePair(input: $request)
  }
`;

export const UPDATE_CC_PERMISSIONS = gql`
  mutation updateContactCenterPermissions(
    $input: UpdateContactCenterPermissionsRequest!
  ) {
    updateContactCenterPermissions(input: $input)
  }
`;

export const CREATE_VENDOR_CONTACT = gql`
  mutation CreateVendorContact($request: VendorContactRequest!) {
    createVendorContact(input: $request) {
      id
    }
  }
`;

export const UPDATE_VENDOR_CONTACT = gql`
  mutation UpdateVendorContact($request: VendorContactRequest!) {
    updateVendorContact(input: $request) {
      id
    }
  }
`;

export const UPLOAD_VENDOR_FILE = gql`
  mutation uploadVendorFile(
    $organizationID: ID!
    $lspID: ID!
    $files: [Upload!]!
  ) {
    vendorFileUpload(
      organizationID: $organizationID
      lspID: $lspID
      files: $files
    ) {
      id
      name
    }
  }
`;

export const UPLOAD_CLIENT_FILE = gql`
  mutation uploadClientFile(
    $organizationID: ID!
    $lspID: ID!
    $files: [Upload!]!
  ) {
    clientFileUpload(
      organizationID: $organizationID
      lspID: $lspID
      files: $files
    ) {
      id
      name
    }
  }
`;

export const VENDOR_ONBOARD = {
  STEP1: gql`
    mutation vendorOnboardStep1($input: VendorOnboardStep1Request!) {
      vendorOnboardStep1(input: $input)
    }
  `,
  STEP2: gql`
    mutation vendorOnboardStep2($input: VendorOnboardStep2Request!) {
      vendorOnboardStep2(input: $input)
    }
  `,
  STEP3: gql`
    mutation vendorOnboardStep3($input: VendorOnboardStep3Request!) {
      vendorOnboardStep3(input: $input)
    }
  `,
  INDIVIDUAL_STEP1: gql`
    mutation vendorIndividualOnboardingStep1(
      $input: VendorIndividualOnboardingStep1Request!
    ) {
      vendorIndividualOnboardingStep1(input: $input)
    }
  `,
  INDIVIDUAL_STEP2: gql`
    mutation vendorIndividualOnboardingStep2(
      $input: UpdateVendorProfileJobTypesRequest!
    ) {
      vendorIndividualOnboardingStep2(input: $input)
    }
  `,
};

export const VENDOR_ONBOARDING_STATUS = gql`
  query vendorOnboardingStatus($vendorID: ID!, $lspID: ID!) {
    vendorOnboardingStatus(vendorID: $vendorID, lspID: $lspID) {
      vendorProfileID
      percentage
      nextStep
      profileName
      vendorType
      currentStep
    }
  }
`;

export const FIND_LINGUIST_ID = gql`
  query findLinguistProfile($userID: ID!, $vendorID: ID!) {
    findLinguistProfile(userID: $userID, vendorID: $vendorID) {
      id
      linguistUniqueID
    }
    crowdVendorProfileExistsByOrg(orgID: $vendorID)
  }
`;

export const UPDATE_QUALIFICATION_STATUS = gql`
  mutation updateQualificationStatus(
    $profileIDs: [ID!]
    $status: String!
    $appName: String!
  ) {
    updateQualificationStatus(
      profileIDs: $profileIDs
      appName: $appName
      status: $status
    )
  }
`;

export const ALL_VENDOR_UNAPPROVED_QUALIFICATIONS = gql`
  query fetchVendorUnapprovedQualifications($lspID: ID!) {
    fetchVendorUnapprovedQualifications(lspID: $lspID) {
      id
      firstName
      lastName
      vendorProfileLanguages {
        id
        languageFrom {
          label: displayName
          value: id
        }
        languageTo {
          label: displayName
          value: id
        }
        industry {
          label: name
          value: id
        }
        jobType {
          label: name
          value: id
          type
        }
        qualificationStatus
      }
    }
  }
`;

export const UPLOAD_VENDOR_BULK_ONBOARDING_FILE = gql`
  mutation uploadVendorBulkOnboardingFile($lspID: ID!, $file: Upload!) {
    uploadVendorBulkOnboardingFile(lspID: $lspID, file: $file)
  }
`;

export const FIND_VENDOR_ONBOARDING_STEP1 = gql`
  query FindVendorOnboardStep1($organizationID: ID!, $lspID: ID!) {
    findVendorOnboardStep1(organizationID: $organizationID, lspID: $lspID) {
      country
      address1
      address2
      city
      postalCode
      state
      industries {
        value: id
        label: name
      }
      firstName
      lastName
      title
      phone
    }
  }
`;

export const FIND_VENDOR_ONBOARDING_STEP2 = gql`
  query FindVendorOnboardStep2($organizationID: ID!, $lspID: ID!) {
    findVendorOnboardStep2(organizationID: $organizationID, lspID: $lspID) {
      country
      address1
      address2
      city
      phone
      state
      postalCode
      fileName
      firstName
      lastName
      faxNumber
      title
    }
  }
`;

export const FIND_LINGUIST_ONBOARDING_STEP1 = gql`
  query FindIndividualOnbardingStep1($organizationID: ID!, $lspID: ID!) {
    findIndividualOnbardingStep1(
      organizationID: $organizationID
      lspID: $lspID
    ) {
      address1
      address2
      country
      city
      phone
      state
      postalCode
    }
  }
`;

export const VENDOR_BULK_ONBOARDING = {
  UPLOAD_FILE: gql`
    mutation uploadVendorBulkOnboardingFile($lspID: ID!, $file: Upload!) {
      uploadVendorBulkOnboardingFile(lspID: $lspID, file: $file)
    }
  `,
  REMOVE_FILE: gql`
    mutation removeVendorBulkOnboardingFile($lspID: ID!, $filePath: String!) {
      removeVendorBulkOnboardingFile(lspID: $lspID, filePath: $filePath)
    }
  `,
  PROCESS: gql`
    mutation processVendorBulkOnboarding($lspID: ID!, $filePath: String!) {
      processVendorBulkOnboarding(lspID: $lspID, filePath: $filePath)
    }
  `,
};

export * from "./profile";
