import { gql } from "@apollo/client";

export const CURRENT_USER = gql`
  fragment CurrentUserFields on CurrentUser {
    id
    firstName
    lastName
    email
    preferredTimezone
    genderID
    gender {
      value: id
      label: name
    }
    prefferedName
    address1
    address2
    city
    state
    zipCode
    country
    phone
    picture {
      url
    }
    notifications
  }
`;
