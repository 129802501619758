import { ModalRoot } from "../context";
import ToastContainer from "../features/alerts/components/toastContainer";

export default function Container({ children }) {
  return (
    <div className="App">
      <ToastContainer />
      <ModalRoot />
      {children}
    </div>
  );
}
