import { useQuery } from "@apollo/client";
import { ALL_INDUSTRIES } from "../queries";
import { noPreference } from "../initialValues";

export const useFetchAllIndustries = (skip) => {
  const { loading, data, error } = useQuery(ALL_INDUSTRIES, {
    skip: skip,
  });

  const industriesWithDefaultOption = [
    noPreference,
    ...(Array.isArray(data?.allIndustries) ? data.allIndustries : []),
  ];

  return {
    loading,
    error,
    allIndustries: data?.allIndustries ?? [],
    industriesWithDefaultOption,
  };
};
