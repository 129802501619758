import { DownloadIcon } from "@heroicons/react/solid";
import { IconButton } from "@lango/common/features/tables/components/IconButton";

const ExportButton = ({ text, onClick, loading }) => {
  return (
    <IconButton
      Icon={DownloadIcon}
      onClick={onClick}
      disabled={loading}
      title={text}
    />
  );
};

export default ExportButton;
