import { Tooltip } from "flowbite-react";
import clx from "@lango/common/classnames";

const Abbreviation = ({ short, long, usePill, className = "" }) => {
  const content = (
    <span
      className={clx("cursor-pointer", className, {
        "mx-0.5 mb-1 inline-flex rounded-md bg-black px-2 py-1 text-xs font-bold uppercase leading-5 text-white":
          usePill,
      })}
    >
      {short}
    </span>
  );

  if (!long) {
    return content;
  }

  return (
    <Tooltip
      style="light"
      content={<div className="w-40 whitespace-normal">{long}</div>}
    >
      {content}
    </Tooltip>
  );
};

export default Abbreviation;
