import { useField, useFormikContext } from "formik";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import cn from "@lango/common/classnames";

import { FieldIcon } from "./FieldIcon";
import InputLabel, { renderError } from "./InputLabel";

/**
 * @param {import("formik").FieldMetaProps} meta
 */
function prepareClasses(meta, readOnly, whiteBackground) {
  return cn(
    "labeled-phone-input rounded-full border border-gray-200 bg-gray-100 py-1 px-5",
    {
      "border-red-300": meta.touched && meta.error,
      "bg-gray-200": readOnly,
      "bg-white": whiteBackground,
    },
  );
}

export default function PhoneNumberInput({
  name,
  disabled,
  placeholder,
  label,
  infoText,
  whiteBackground = false,
  icon,
  ...rest
}) {
  const [field, meta, helpers] = useField(name);
  const isSubmitting = useFormikContext().isSubmitting;

  return (
    <div className="text-left">
      {label && <InputLabel labelFor={name} text={label} infoText={infoText} />}
      <div className="relative bg-white">
        <PhoneInput
          {...field}
          value={field.value}
          onChange={(value) => helpers.setValue(value)}
          className={prepareClasses(meta, disabled, whiteBackground)}
          numberInputProps={{
            className: "bg-inherit",
          }}
          defaultCountry="US"
          disabled={isSubmitting || disabled}
          placeholder={placeholder}
          international
          {...rest}
        />
        <FieldIcon icon={icon} meta={meta} />
      </div>
      {renderError(meta)}
    </div>
  );
}
