export const NOTIFICATION_TYPES = {
  NEW_INVITE: "New Invitation",
  Vendor_Qualification: "Vendor Qualification",
  Bulk_Onboarding: "Bulk Onboarding",
};

export const NOTIFICATION_ACTIONS = {
  ACCEPT: "Accept",
  MARK_AS_READ: "Mark as read",
};
