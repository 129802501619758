/**
 * Invitation types
 * Used to indicate the type of invitation a pending invitation is
 * Primarily used to indicate if a PM invitation is an organization invite or an agency invite
 */

export const INVITATION_TYPES = {
  CLIENT: "client",
  VENDOR: "vendor",
  LSP: "lsp",
};

export function isLSPInvitation(invitationType) {
  return invitationType === INVITATION_TYPES.LSP;
}

export function isClientInvitation(invitationType) {
  return invitationType === INVITATION_TYPES.CLIENT;
}
