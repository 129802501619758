import { gql } from "@apollo/client";

import { VENDOR_PROFILE } from "@lango/common/fragments";

export const PAGINATED_VENDOR_PROFILES = gql`
  ${VENDOR_PROFILE}
  query allOrgVendors($input: GetAllOrgVendorsRequest!) {
    allOrgVendors(input: $input) {
      vendorProfiles {
        ...vendorProfileBasicFields
        vendorProfileType {
          id
          name
        }
        pendingActions {
          type
          title
          description
        }
      }
      totalRecords
    }
  }
`;
