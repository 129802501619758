import { Spinner } from "flowbite-react";

const PageLoader = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <Spinner size="xl" color="gray" />
    </div>
  );
};

export default PageLoader;
