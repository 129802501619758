import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { Outlet, useNavigate } from "react-router-dom";

import PageLoader from "./page-loader";
import { useEffect } from "react";

export default withAuthenticationRequired(Outlet, {
  onRedirecting: () => <PageLoader />,
});

export function UnauthenticatedGuard() {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    isAuthenticated && navigate("/");
  }, [navigate, isAuthenticated]);

  if (isLoading) {
    return <PageLoader />;
  }

  return isAuthenticated ? null : <Outlet />;
}
