import { Formik } from "formik";

import { WithLoader } from "@lango/common/components";
import { useFetchCurrentUser } from "@lango/common/hooks";

import Fields from "./fields";

const Notifications = () => {
  const { data, ...props } = useFetchCurrentUser();
  return (
    <WithLoader {...props}>
      <Formik initialValues={data} onSubmit={() => {}} enableReinitialize>
        <Fields />
      </Formik>
    </WithLoader>
  );
};

export default Notifications;
