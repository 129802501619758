import { authStateVar, usernameVar } from "../apollo";

export const preparePaginatedJobTaskRequest = (props = {}) => {
  const { pageIndex, pageSize, sortBy, jobID, organizationID } = props;
  return {
    variables: {
      organizationID,
      jobID,
      offset: pageIndex * pageSize,
      pageLimit: pageSize,
      ...prepareSortBy(sortBy),
    },
  };
};

const prepareSortBy = (sortBy) => {
  return sortBy?.length > 0
    ? { sortBy: sortBy[0].id, sortDir: sortBy[0].desc ? "DESC" : "ASC" }
    : { sortBy: "task.id", sortDir: "DESC" };
};

export const formatCode = (code) => {
  if (!code) return "";
  const codeStr = code.toString();
  return `${codeStr.slice(0, 3)}-${codeStr.slice(3, 6)}-${codeStr.slice(6)}`;
};

export const prepareLinguistProfile = (data) => {
  const profile = data?.findLinguistProfile;
  const user = profile?.user;
  return {
    name: user ? `${user.firstName} ${user.lastName}` : "",
    linguistProfileID: profile?.id,
    isActive: profile?.isActive,
    linguistUniqueID: formatCode(profile?.linguistUniqueID),
  };
};

/**
 * Formats a given phone number as a US standard phone number.
 *
 * This function takes a numeric or string input and returns the phone number
 * formatted in the standard US format (e.g. (415) 555-0132). If no input is
 * provided or the input is empty, it returns an empty string.
 */
export const formatPhoneNumberUS = (number = "") => {
  if (!number) return "";

  // Filter non-numeric characters
  const regex = () => number.replace(/[^0-9]+/g, "");

  // Set area code with parenthesis around it
  const areaCode = () => `(${regex().slice(0, 3)})`;

  // Set formatting for first six digits
  const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

  // Dynamic trail as user types
  const trailer = (start) => `${regex().slice(start, regex().length)}`;

  let formattedNumber;
  const length = regex().length;

  if (length <= 3) {
    // First 3 digits
    formattedNumber = regex();
  } else if (length <= 6) {
    // After area code
    formattedNumber = `${areaCode()} ${trailer(3)}`;
  } else if (length <= 10) {
    // Before dash
    formattedNumber = `${firstSix()}-${trailer(6)}`;
  } else {
    // After dash
    formattedNumber = `${firstSix()}-${trailer(6)}`;
  }

  return formattedNumber;
};
