import WithLoader from "@lango/common/components/WithLoader";
import JobFilterModal from "@lango/common/components/jobFilter";
import Table from "@lango/common/features/tables/components/Table";
import { useJobsQuickFilters } from "@lango/common/hooks/useJobsQuickFilters";
import { filterKeys } from "@lango/common/request";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { jobTableColumns } from "./columns";
import { useFetchPaginatedVendorJobs } from "./fetchVendorJob";
import { ExportProjectJobs } from "@lango/common/components";
import { VENDOR_APP } from "@lango/common/constants";

const JobsTable = () => {
  const columns = useMemo(() => jobTableColumns, []);
  const navigate = useNavigate();

  const { jobs, totalJobs, fetchPaginatedVendorJobs, ...rest } =
    useFetchPaginatedVendorJobs();

  const quickFilters = useJobsQuickFilters({ includeMT: false });

  const handleClickTask = (value) => {
    navigate("/requests/job/" + value.id);
  };

  return (
    <>
      <Table
        heading={"requests"}
        enablePagination={true}
        columns={columns}
        data={jobs}
        totalRecords={totalJobs}
        fetchData={fetchPaginatedVendorJobs}
        SearchModal={JobFilterModal}
        hideFilters={[filterKeys.billingReference, filterKeys.labels]}
        quickFilters={quickFilters}
        expandQuickFilters={true}
        showColumns
        onRowClick={handleClickTask}
        ExportComponent={ExportProjectJobs}
        exportProps={{
          className: "mr-5",
          exportType: VENDOR_APP,
        }}
      />
      <WithLoader {...rest} />
    </>
  );
};

export default JobsTable;
