import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { FIND_CURRENT_USER } from "@lango/common/queries";

import { prepareUserTimezone } from "../features/userSettings/userSettingForm/timezones";
import environmentVars from "../env";

export const useFetchCurrentUser = () => {
  const { loading, data, error } = useQuery(FIND_CURRENT_USER, {
    variables: {
      applicationName: environmentVars.appName,
    },
  });

  const user = useMemo(() => prepareUserTimezone(data), [data]);

  return {
    loading,
    error,
    currentUser: user,
  };
};
