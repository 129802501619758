import React, { useCallback, useState } from "react";

import BaseModal from "@lango/common/components/baseModal";
import { useChannelEvent } from "@lango/common/pusher";

import ExportModalBody from "./ExportModalBody";

const ExportModal = ({
  title,
  isOpen,
  onClose,
  channelName,
  channelEvent,
}) => {
  const [fileStatus, setFileStatus] = useState();

  const handleUpdateFileStatus = useCallback((data) => {
    setFileStatus(data);
  }, []);
  useChannelEvent(channelName, channelEvent, handleUpdateFileStatus);

  return (
    <BaseModal isOpen={isOpen} onRequestClose={onClose} title={title}>
      <ExportModalBody
        fileStatus={fileStatus}
      />
    </BaseModal>
  );
};

export default ExportModal;
