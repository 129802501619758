import { saveAs } from "file-saver";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import ProgressBar from "@lango/common/components/ProgressBar";
import Loader from "@lango/common/features/forms/components/Loader";
import { renderDownloadButton } from "../exportUtils";
import { toastError } from "@lango/common/features";

const ExportModalBody = ({ fileStatus }) => {
  const progress = fileStatus?.progress;
  const status = fileStatus?.status;
  const isFailed = status === "failed";
  const isCompleted = status === "completed";
  const fileName = fileStatus?.fileName;
  const fileURL = fileStatus?.fileURL;

  const handleDownload = async () => {
    try {
      saveAs(fileURL, fileName);
    } catch (error) {
      console.error("Download failed:", error);
      toastError("Download Failed");
    }
  };

  return (
    <div className="p-4">
      <ProgressBar progress={progress} color={isFailed ? "red" : "green"} />
      {!isFailed && !isCompleted && <Loader />}
      {isCompleted && fileName?.length > 0 && (
        <>
          <span className="mt-2 block">{fileName}</span>
          {renderDownloadButton(isFailed, handleDownload)}
        </>
      )}
      {isFailed && (
        <span className="mt-2 flex text-red-600">
          FAILED
          <ExclamationCircleIcon className="ml-0.5 w-4" />
        </span>
      )}
    </div>
  );
};

export default ExportModalBody;
