import { rateFilterKeys, rateFilterType } from "@lango/common/request";

import { INPUT_DATE_FORMAT } from "@lango/common/constants";
import { getCountryOption } from "../constants/countries";
import moment from "moment-timezone";
import { renderFullName } from "@lango/common/helpers";
import { renderSourceType } from "../helpers/render";

export const loginFormInitialValues = {
  username: "",
  password: "",
  localLogin: false,
  ssoProviderName: "",
  disableFields: false,
};

export const confirmPasswordInitialValues = (username = "") => {
  return {
    username,
    code: "",
    newPassword: "",
    repeatNewPassword: "",
  };
};

export const forgotPasswordInitialValues = { username: "" };

export const noPreference = { label: "No Preference", value: 0 };

/**
 * JobRoutingInitialValues Object
 *
 *  @returns {Object} the initial values for creating a job routing.
 */
export const jobRoutingInitialValues = {
  name: "",
  sourceLanguage: null,
  targetLanguage: [],
  jobType: null,
  useLangoCrowd: null,
  industry: null,
  enableGeneralRouting: false,
};

/**
 * routingLevelInitialValues Object
 *
 *  @returns {Object} the initial values for creating a new routing level.
 */
export const routingLevelInitialValues = {
  levelName: "",
  vendorProfiles: [],
};

export const prepareRoutingLevelInitialValues = (values = {}) => {
  const { name } = values;
  return {
    levelName: name,
  };
};

/**
 * assignVendorsInitialValues Object
 *
 *  @returns {Object} the initial values for assigning new vendors to routing level.
 */
export const assignVendorsInitialValues = {
  vendors: [],
};

/**
 * mtUsageFilterInitialValues Object
 *
 *  @returns {Object} the initial values for client MT usage filter.
 */
export const mtUsageFilterInitialValues = {
  filter: {
    value: "LastSevenDays",
    label: "Last 7 Days",
  },
};

/**
 * pmDashboardInitialValues Object
 *
 *  @returns {Object} the initial values for PM app dashboard jobs & stats.
 */
export const pmDashboardInitialValues = {
  filter: {
    value: "Today",
    label: "Today",
  },
};

/**
 * Prepares organization labels
 *
 * @param {Array<Object>} labels - The array of label objects.
 * @returns {Array<string>} The array of label strings.
 */
const prepareOrgLabels = (labels) => labels?.map((label) => label?.label);

/**
 * Prepares the estimated price by formatting it to two decimal places.
 *
 * @param {number} estimatedPrice - The estimated price.
 * @returns {string} - The formatted estimated price string.
 */
const prepareEstimatedPrice = (estimatedPrice) =>
  estimatedPrice ? estimatedPrice.toFixed(2) : "0.00";

/**
 * prepareHTInitialFormValues Function
 *
 * This function generates initial values for HT job form based on the provided data.
 *
 *  @returns {Object} the initial values for HT job form.
 */
export const prepareHTInitialFormValues = (values = {}) => {
  const {
    internalNotes = "",
    customerNotes = "",
    jobType = {},
    labels = [],
    organization = {},
    createdByUser,
    languageFrom,
    languageTo,
    estimatedPrice,
    requestedCompletionDate: requestedDate,
    jobFiles = [],
    jobStatus,
    wordCount = 0,
  } = values;

  return {
    internalNotes,
    customerNotes,
    jobtype: jobType.name || "",
    orgLabels: prepareOrgLabels(labels),
    company: organization.name || "",
    requester: renderFullName(createdByUser),
    sourceLanguage: languageFrom,
    targetLanguage: languageTo,
    estimatedPrice: prepareEstimatedPrice(estimatedPrice),
    requestedDate: requestedDate
      ? moment(requestedDate).format(INPUT_DATE_FORMAT)
      : "",
    jobFiles,
    jobStatus,
    wordCount,
  };
};

/**
 * prepareJobInitialFormValues Function
 *
 * This function generates initial values for job form based on the provided data.
 *
 *  @returns {Object} the initial values for job form.
 */
export const prepareJobInitialFormValues = (values = {}) => {
  const {
    internalNotes = "",
    customerNotes = "",
    labels = [],
    jobType = {},
    organization,
    createdByUser,
    languageFrom: sourceLanguage,
    languageTo: targetLanguage,
    estimatedPrice,
    createdAt,
    jobStatus,
  } = values;

  return {
    internalNotes,
    customerNotes,
    jobtype: jobType.name || "",
    orgLabels: prepareOrgLabels(labels),
    company: organization?.name || "",
    requester: renderFullName(createdByUser),
    sourceLanguage,
    targetLanguage,
    estimatedPrice: prepareEstimatedPrice(estimatedPrice),
    organizationID: organization?.id,
    createdAt: createdAt ? moment(createdAt).format(INPUT_DATE_FORMAT) : "",
    jobStatus,
  };
};

/**
 * prepareOnDemandInitialFormValues Function
 *
 * This function generates initial values for on-demand job form based on the provided data.
 *
 *  @returns {Object} the initial values for on-demand job form.
 */
export const prepareOnDemandInitialFormValues = (values = {}) => {
  const {
    internalNotes = "",
    customerNotes = "",
    labels = [],
    jobType = {},
    organization,
    createdByUser,
    languageFrom: sourceLanguage,
    languageTo: targetLanguage,
    estimatedPrice,
    createdAt,
    tasks = [],
    jobStatus,
    sourceType,
    calledNumber,
  } = values;
  const task = tasks[0] || {};

  return {
    internalNotes,
    customerNotes,
    jobtype: jobType.name || "",
    orgLabels: prepareOrgLabels(labels),
    company: organization?.name || "",
    requester: renderFullName(createdByUser),
    sourceLanguage,
    targetLanguage,
    estimatedPrice: prepareEstimatedPrice(estimatedPrice),
    organizationID: organization?.id,
    createdAt: createdAt ? moment(createdAt).format(INPUT_DATE_FORMAT) : "",
    gender: task?.gender?.label || "",
    vendorAssigned: {
      name: task?.taskAssignedVendor?.name,
      profileType: task?.taskAssignedVendor?.profileType,
      isLangoCrowdPartner: task?.taskAssignedVendor?.isLangoCrowdPartner,
    },
    jobStatus,
    startTime: prepareTimeFieldValue(task?.startTime),
    endTime: prepareTimeFieldValue(task?.endTime),
    servicedByCrowd: task?.servicedByCrowd,
    sourceType: renderSourceType({ sourceType, calledNumber }),
  };
};

function prepareTimeFieldValue(time) {
  return time ? moment(time).format("HH:mm") : "";
}

/**
 * prepareHTJobTaskInitialValues Function
 *
 * This function generates initial values for HT job task modal form based on the provided data.
 *
 *  @returns {Object} the initial values for HT job task modal.
 */
export const prepareHTJobTaskInitialValues = (row = {}) => {
  const { id, taskType, rate, targetDate, vendor } = row;
  return {
    id,
    taskType,
    rate: rate || "0.00",
    vendor,
    targetDate: targetDate ? moment(targetDate).format(INPUT_DATE_FORMAT) : "",
  };
};

export const vendorLanguageInitialValues = {
  sourceLanguage: null,
  targetLanguage: null,
  jobType: null,
  industry: null,
};

export const vendorLanguageUpdateInitialValues = (row = {}) => {
  const { languageFrom, languageTo, industry, id, jobType } = row;
  return {
    id,
    sourceLanguage: languageFrom,
    targetLanguage: languageTo,
    industry,
    jobType,
  };
};

/**
 * vendorContactInitialValues Object
 *
 * @returns {Object} The initial values for adding vendor contact.
 */
export const vendorContactInitialValues = ({
  firstName = "",
  lastName = "",
  email = "",
  phone = "",
  title = "",
  faxNumber = "",
  country = "",
  address1 = "",
  address2 = "",
  city = "",
  postalCode = "",
  taxFormID = null,
  state = "",
}) => ({
  firstName,
  lastName,
  email,
  phone,
  title,
  faxNumber,
  country: getCountryOption(country),
  address1,
  address2,
  city,
  postalCode,
  taxFormID,
  state,
});

export const vendorOnboardStep1InitialValues = ({
  name = "",
  firstName = "",
  lastName = "",
  email = "",
  address1 = "",
  address2 = "",
  city = "",
  country = "",
  postalCode = "",
  state = "",
  industries = [],
  title = "",
  phoneNumber = "",
}) => {
  return {
    name,
    firstName,
    lastName,
    email,
    address1,
    address2,
    city,
    postalCode,
    state,
    country: getCountryOption(country),
    industries,
    title,
    phoneNumber,
  };
};

/**
 * clientContactInitialValues Object
 *
 * @returns {Object} The initial values for adding vendor contact.
 */
export const clientContactInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  title: "",
  city: "",
  country: null,
  address1: "",
  address2: "",
  postalCode: "",
};

/**
 * Client Contact Form Initial Values
 * @returns {Object} The
 */
export const clientContactFormInitialValues = ({
  name = "",
  firstName = "",
  lastName = "",
  email = "",
  phone = "",
  title = "",
  website = "",
  country = "",
  address1 = "",
  address2 = "",
  city = "",
  postalCode = "",
}) => ({
  name,
  firstName,
  lastName,
  email,
  phone,
  title,
  website,
  country: getCountryOption(country),
  address1,
  address2,
  city,
  postalCode,
});

export const clientOnboardStep1InitialValues = (name = "") => ({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  website: "",
  title: "",
  faxNumber: "",
  name,
  industries: [],
});

export const clientOnboardStep3InitialValues = {
  serviceLocations: [
    {
      locationName: "",
      country: null,
      address1: "",
      address2: "",
      city: "",
      postalCode: "",
      phone: "",
    },
  ],
};

export const individualOnboardStep2InitialValues = ({
  firstName = "",
  lastName = "",
  email = "",
  address1 = "",
  address2 = "",
  city = "",
  postalCode = "",
  country = "",
  state = "",
  phone = "",
  gender = null,
}) => ({
  firstName,
  lastName,
  email,
  gender,
  phone,
  address1,
  address2,
  city,
  postalCode,
  country: getCountryOption(country),
  state,
});

export const createVendorInitialValues = {
  name: "",
  firstName: "",
  lastName: "",
  vendorType: null,
};

export const createVendorRateSheetValues = {
  organizationID: null,
  jobType: null,
  name: "",
  rateUnit: null,
  rate: (0.0).toFixed(2),
  effectiveTimeTo: null,
  industries: [],
  rounding: null,
  amount: null,
  description: "",
  languagePairs: [{ languageFrom: null, languageTo: null }],
  multiLanguagePairs: null,
  incrementalRates: [],
  rateSheetType: "",
  additionalRates: {
    weekendRate: {
      rate: (0.0).toFixed(2),
      isEnabled: false,
      rateAdjustmentType: null,
    },
    holidayRate: {
      rate: (0.0).toFixed(2),
      isEnabled: false,
      rateAdjustmentType: null,
    },
    afterHoursRate: {
      rate: (0.0).toFixed(2),
      isEnabled: false,
      rateAdjustmentType: null,
    },
  },
};

// Helper function to get the current filter values
const getCurrentFilter = (currentFilters, needle) => {
  const values =
    currentFilters &&
    currentFilters[rateFilterType[needle]]?.find((f) => f?.key === needle)
      ?.values;
  return values || [];
};

/**
 * Generates the initial values for rate sheet filters based on the current filters and hides specified filters.
 *
 * @param {Object} currentFilters - The current filters applied.
 * @param {Array<string>} hideFilters - The filters to be hidden.
 * @returns {Object} The initial values for the filters with specified filters hidden.
 */
export const rateSheetFiltersInitialValues = (currentFilters, hideFilters) => {
  const initialValues = {
    [rateFilterKeys.name]: getCurrentFilter(
      currentFilters,
      rateFilterType.name,
    ),
    [rateFilterKeys.jobType]: getCurrentFilter(
      currentFilters,
      rateFilterType.jobType,
    ),
    [rateFilterKeys.languageTo]: getCurrentFilter(
      currentFilters,
      rateFilterType.languageTo,
    ),
    [rateFilterKeys.languageFrom]: getCurrentFilter(
      currentFilters,
      rateFilterType.languageFrom,
    ),
  };

  hideFilters.forEach((filter) => {
    delete initialValues[filter];
  });

  return initialValues;
};

export const updateClientUserPhoneNumberInitialValues = (row) => {
  return {
    user: { value: row?.user?.id, label: renderFullName(row?.user) },
    phoneNumber: row.phoneNumber,
  };
};
