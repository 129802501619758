import { ADDITIONAL_RATE_TYPES, isSpecificType } from "@lango/common/constants";
import { emptyFilters, prepareSortBy } from "./index";
const { HOLIDAY_RATE, AFTER_HOURS_RATE, WEEKEND_RATE } = ADDITIONAL_RATE_TYPES;

const filterTypes = {
  common: "commonFilters",
  custom: "customFilters",
};

export const rateFilterKeys = {
  name: "name",
  languageFrom: "languageFrom",
  languageTo: "languageTo",
  jobType: "jobType",
  search: "search",
  languagePair: "languagePair",
};

export const rateFilterType = {
  [rateFilterKeys.name]: filterTypes.common,
  [rateFilterKeys.jobType]: filterTypes.common,
  [rateFilterKeys.languageTo]: filterTypes.custom,
  [rateFilterKeys.languageFrom]: filterTypes.custom,
  [rateFilterKeys.languagePair]: filterTypes.custom,
  [rateFilterKeys.search]: filterTypes.custom,
};

const orgRateFuzzyFilters = [
  rateFilterKeys.name,
  rateFilterKeys.languagePair,
  rateFilterKeys.search,
];

const rateExactMatchFilters = [rateFilterKeys.jobType];

/**
 * Creates a vendor rate sheet request object.
 *
 * @param {Object} [values={}] - The values to create the rate sheet request.
 * @param {string} [values.organizationID] - The ID of the organization.
 * @param {Object} [values.jobType] - The job type object.
 * @param {string} [values.jobType.value] - The ID of the job type.
 * @param {number} [values.id] - The ID of the rate sheet.
 * @param {Array<Object>} [values.industries] - The list of industries.
 * @param {string} [values.name] - The name of the rate sheet.
 * @param {Object} [values.rateUnit] - The rate unit object.
 * @param {string} [values.rateUnit.value] - The ID of the rate unit.
 * @param {any} [values.rate] - The rate value.
 * @param {Array<Object>} [values.incrementalRates] - The list of incremental rates.
 * @param {Object} [values.rounding] - The rounding object.
 * @param {string} [values.rounding.value] - The rounding value.
 * @param {Object} [values.additionalRates] - The additional Rates object.
 * @param {Object} [values.amount] - The amount object.
 * @param {number|string} [values.amount.value] - The amount value.
 * @param {Object} [values.weekendRate] - The weekendRate object.
 * @param {string} [values.description] - The description of the rate sheet.
 * @param {string} [values.rateSheetType] - The type of the rate sheet.
 * @param {number} [values.vendorProfileID] - The ID of the vendor profile.
 * @param {boolean} [values.assignVendors] - Whether to assign vendors to the rate sheet.
 * @param {Object} [values.effectiveTimeTo] - The effective time to value.
 * @param {boolean} [values.isDuplicate] - Whether the rate sheet is a duplicate.
 * @returns {Object} The vendor rate sheet request object.
 */
export const prepareVendorRateSheetRequest = (values = {}) => {
  const {
    organizationID,
    jobType,
    industries,
    name,
    rateUnit,
    rate,
    rounding,
    amount,
    description,
    assignVendors = false,
    incrementalRates,
    effectiveTimeTo,
    rateSheetType,
    vendorProfileID,
    additionalRates,
    id,
    isDuplicate,
  } = values;
  const isUpdate = !isDuplicate && id && id !== 0;
  const newAdditionalRates = [];
  if (additionalRates?.weekendRate?.isEnabled) {
    newAdditionalRates.push({
      ...prepareAdditionalRate(additionalRates, WEEKEND_RATE.key),
      type: WEEKEND_RATE.label,
    });
  }

  if (additionalRates?.holidayRate?.isEnabled) {
    newAdditionalRates.push({
      ...prepareAdditionalRate(additionalRates, HOLIDAY_RATE.key),
      type: HOLIDAY_RATE.label,
    });
  }

  if (additionalRates?.afterHoursRate?.isEnabled) {
    newAdditionalRates.push({
      ...prepareAdditionalRate(additionalRates, AFTER_HOURS_RATE.key),
      type: AFTER_HOURS_RATE.label,
    });
  }

  return {
    variables: {
      input: {
        ...(isUpdate && { id }), // Include `id` for updates
        ...(!isUpdate && {
          assignVendors,
          rateSheetType,
          vendorProfileID: vendorProfileID ?? 0,
        }), // Only include for creation
        name,
        organizationID,
        effectiveTimeTo: incrementalRates.length > 0 ? effectiveTimeTo?.value : 0,
        jobTypeID: jobType?.value,
        description,
        rateUnitID: rateUnit?.value || 0,
        rounding: rounding?.value || "",
        amount: amount?.value || 0,
        rate: rate ? parseFloat(rate) : 0.0,
        industryIDs: industries?.map((industry) => industry?.value) || [],
        languagePairs: prepareLanguagePairs(rateSheetType, values),
        incrementalRates: incrementalRates.map((increment) => ({
            incrementalRate: increment?.incrementalRate || 0,
            effectiveTimeFrom: increment?.effectiveTimeFrom?.value || 0,
            effectiveTimeTo: increment?.effectiveTimeTo?.value || 0,
          })) ?? [],
        additionalRates: newAdditionalRates,
      },
    },
  };
};

const prepareAdditionalRate = (additionalRates, key) => ({
  rate: parseFloat(additionalRates?.[key]?.rate) || 0.0,
  rateAdjustmentType: additionalRates?.[key]?.rateAdjustmentType?.value,
});

/**
 * Generates a request object for fetching paginated vendor rate sheets.
 *
 * @param {Object} props - The properties for the request.
 * @param {number} props.pageIndex - The current page index.
 * @param {number} props.pageSize - The number of items per page.
 * @param {Array} props.sortBy - The sorting criteria.
 * @param {number} props.organizationID - The ID of the organization.
 * @param {string} [props.rateSheetType] - The type of the rate sheet.
 * @param {Object} [props.filters] - Optional filters for the request.
 * @returns {Object} The request object with variables and filtersRequest.
 */
export const paginatedVendorRateSheetsRequest = (props) => {
  const {
    pageIndex,
    pageSize,
    sortBy,
    organizationID,
    filters,
    rateSheetType,
  } = props;

  return {
    variables: {
      input: {
        organizationID,
        rateSheetType: rateSheetType,
        offset: pageIndex * pageSize,
        pageLimit: pageSize,
        ...prepareSortBy(sortBy),
      },
      filtersRequest: filters || emptyFilters,
    },
  };
};

/**
 * Adds a filter to the filters object based on the provided key and values.
 *
 * @param {Object} filters - The filters object to which the filter will be added.
 * @param {string} key - The key identifying the filter type.
 * @param {Array} values - The values associated with the filter.
 * @param {boolean} [like=false] - Whether the filter uses a fuzzy match (true) or exact match (false).
 */
const addFilter = (filters, key, values, like = false) => {
  if (values.length && filters[rateFilterType[key]]) {
    filters[rateFilterType[key]].push({ key, values, like });
  }
};

/**
 * Builds the organization rate filters request based on the provided form values.
 * It handles both exact match and fuzzy filters, as well as combining language pairs.
 *
 * @param {Object} formValues - The form values used to build the filters.
 * @returns {Object} The filters object containing common and custom filters.
 */
export const buildVendorRateSheetFiltersRequest = (formValues) => {
  // initialize the filters object with common and custom filter types
  const filters = { [filterTypes.common]: [], [filterTypes.custom]: [] };
  const languagePairSet = new Set();

  // Retrieves the form values for the specified key and converts them to an array of strings.
  const getValues = (key) => {
    const fieldValues = formValues[key] || [];
    return Array.isArray(fieldValues)
      ? fieldValues.map((v) => String(v?.value || v)) // handle array of values
      : [String(fieldValues?.value || fieldValues)]; // handle single value
  };

  // iterate over both exact match and fuzzy filters, adding them to the filters object
  [...rateExactMatchFilters, ...orgRateFuzzyFilters].forEach((key) => {
    const values = getValues(key);
    if (values.length)
      addFilter(filters, key, values, orgRateFuzzyFilters.includes(key));
  });

  // get values for languageFrom and languageTo from the form
  const languageFrom = getValues("languageFrom")[0];
  const languageTo = getValues("languageTo")[0];

  // if both languageFrom and languageTo are present, combine them into a language pair
  if (languageFrom && languageTo) {
    const combinedValues = `${languageFrom},${languageTo}`;
    if (!languagePairSet.has(combinedValues)) {
      languagePairSet.add(combinedValues); // avoid duplicate language pairs
      addFilter(filters, "languagePair", [languageFrom, languageTo], true); // add as fuzzy filter
    }
  }

  return filters;
};

/**
 * Prepares an array of language pairs based on the rate sheet type and provided values.
 *
 * @param {string} rateSheetType - The type of the rate sheet.
 * @returns {Array} An array of prepared language pairs with `languageFromID` and `languageToID`.
 */
export const prepareLanguagePairs = (rateSheetType, values) => {
  if (isSpecificType(rateSheetType)) {
    return (
      values?.multiLanguagePairs?.map((multiPair) => ({
        languageFromID: multiPair?.pair?.languageFrom?.value,
        languageToID: multiPair?.pair?.languageTo?.value,
      })) || []
    );
  } else {
    return (
      values?.languagePairs?.map((pair) => ({
        languageFromID: pair?.languageFrom?.value,
        languageToID: pair?.languageTo?.value,
      })) || []
    );
  }
};
