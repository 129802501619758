import { Outlet } from "react-router-dom";
import BrowserAgentOptions from "@lango/common/apm/browserAgentOptions";
import { HubspotChat } from "@lango/common/components/HubspotChatWidget";
import AutoLogout from "@lango/common/features/auth/components/AutoLogout";
import Header from "@lango/common/features/header/components";
import HeaderLink from "@lango/common/features/header/components/HeaderLink";
import { VENDOR_ROUTES } from "@lango/common/routes";

import OnDemandRequest from "./containers/OnDemandRequest";
import { WithAuthenticated } from "@lango/common/features/auth/components/UserAuthenticated";

// set up New Relic Browser Agent
BrowserAgentOptions();

const AppContainer = () => {
  return (
    <div className="flex h-screen flex-col">
      <Header extraClasses="items-center justify-between border-l-2 border-gray-200">
        <HeaderLink to={VENDOR_ROUTES.REQUESTS} name="Requests" />
        <OnDemandRequest />
      </Header>
      <main className="grow py-4 px-4 sm:py-8 sm:px-6 lg:py-10 lg:px-8">
        <section className="mx-auto grid max-w-screen-2xl grid-cols-1">
          <WithAuthenticated>
            <AutoLogout />
            <HubspotChat />
          </WithAuthenticated>
          <Outlet />
        </section>
      </main>
    </div>
  );
};

export default AppContainer;
