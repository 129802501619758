import React from "react";
import { HttpStatusCode } from "axios";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from "@auth0/auth0-react";
// @ts-ignore
import { createUploadLink } from "apollo-upload-client";

import { cache } from "./cache";

// graphQL endpoints
const protectedLink = createUploadLink({
  uri: (operation) =>
    `${process.env.REACT_APP_GRAPHQL_URL}?${operation.operationName}`,
});
const publicLink = createHttpLink({
  uri: (operation) =>
    `${process.env.REACT_APP_GRAPHQL_PUBLIC_URL}?${operation.operationName}`,
});

export default function Provider({ children }) {
  const { getAccessTokenSilently, logout } = useAuth0();

  const client = React.useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently();

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
          "X-Auth-Idp": "auth0",
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError) {
        console.error("[Network error]:", networkError);
        if (networkError?.response?.status === HttpStatusCode.Unauthorized) {
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
      }
    });

    const protectedChain = from([errorLink, authLink, protectedLink]);

    return new ApolloClient({
      link: split(
        (operation) => operation.getContext().clientName === "public",
        publicLink,
        protectedChain,
      ),
      cache: cache,
    });
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
