import { gql } from "@apollo/client";

export const ASSIGN_VENDOR_TO_ROUTING_LEVEL = gql`
  mutation AssignVendorsToRoutingLevel(
    $routingLevelID: ID!
    $vendorProfileIDs: [Int!]!
  ) {
    assignVendorsToRoutingLevel(
      routingLevelID: $routingLevelID
      vendorProfileIDs: $vendorProfileIDs
    )
  }
`;

export const UNASSIGN_VENDOR_ROUTING_LEVEL = gql`
  mutation UnassignVendorRoutingLevel($routingLevelID: ID!, $vendorID: Int!) {
    unassignVendorRoutingLevel(
      routingLevelID: $routingLevelID
      vendorID: $vendorID
    )
  }
`;

export const DELETE_ROUTING_LEVEL = gql`
  mutation deleteRoutingLevel($routingLevelID: ID!) {
    deleteRoutingLevel(routingLevelID: $routingLevelID)
  }
`;

export const FIND_JOB_ROUTING = gql`
  query FindJobRouting($lspID: ID!, $jobRoutingID: ID!) {
    findJobRouting(lspID: $lspID, jobRoutingID: $jobRoutingID) {
      id
      name
      jobType {
        label: name
        value: id
      }
      sourceLanguage: languageFrom {
        label: displayName
        value: id
        isoCodeLong
      }
      targetLanguage: languageTo {
        label: displayName
        value: id
        isoCodeLong
      }
      industry: industries {
        label: name
        value: id
      }
      useLangoCrowd
      enableGeneralRouting
    }
  }
`;
