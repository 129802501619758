import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
});

export const useAxios = () => {
  const { getAccessTokenSilently } = useAuth0();
  axiosInstance.interceptors.request.use(
    async (config) => {
      try {
        const token = await getAccessTokenSilently();
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["X-Auth-Idp"] = "auth0";
      } catch (error) {
        console.error("Error fetching authentication token:", error);
        return Promise.reject(error);
      }
      return config;
    },
    (error) => {
      console.error("Request error:", error);
      return Promise.reject(error);
    },
  );

  return { axiosInstance };
};
