/**
 * @file AcceptVendorInviteContext.js
 * @description This file contains the implementation of the AcceptVendorInviteContext and related components.
 * The AcceptVendorInviteContext provides a context for managing the state and actions related to accepting a vendor invitation.
 * It includes the AcceptVendorInviteProvider component, which wraps the component and provides the context values.
 * The useAcceptVendorInviteCtx hook is used to access the context values within the components.
 */

import { createContext, useCallback, useContext, useState } from "react";
import { Loader } from "@lango/common/components";
import { Step1, Step2, Step3 } from "../features/onboarding";
import { isVendorApp } from "@lango/common/env";

export const AcceptVendorInviteContext = createContext({
  inviteStep: "",
  verifyResponse: null,
  onboardingData: null,
  redirectMode: false,
  handleChangeInviteStep: (_val) => {},
  handleChangeVerifyResponse: (_val, _step) => {},
  handleChangOnboardingData: (_val, _step) => {},
  /**
   * @returns {boolean}
   */
  isIndividual: () => false,
});

/**
 * @typedef {Object} AcceptVendorInviteProviderProps
 * @property {boolean} [callbackMode=false] - Indicates whether the component is in callback mode.
 * @property {React.ReactNode} children - The child components.
 */

/**
 * @param {AcceptVendorInviteProviderProps} props - The component props.
 * @returns {React.ReactNode} The AcceptVendorInviteProvider component.
 */
export function AcceptVendorInviteProvider({ callbackMode = false, children }) {
  const [inviteStep, setInviteStep] = useState("");
  const [verifyResponse, setVerifyResponse] = useState(null);
  const [onboardingData, setOnboardingData] = useState(null);
  const [redirectMode] = useState(callbackMode);

  const handleChangeInviteStep = useCallback((val) => {
    setInviteStep(val);
  }, []);

  const handleChangeVerifyResponse = useCallback((val, step) => {
    setVerifyResponse(val);
    setInviteStep(step);
  }, []);

  const handleChangOnboardingData = useCallback((val, step) => {
    setOnboardingData(val);
    setInviteStep(step);
  }, []);

  function isIndividual() {
    return onboardingData?.vendorType === "Individual" && isVendorApp;
  }

  return (
    <AcceptVendorInviteContext.Provider
      value={{
        inviteStep,
        verifyResponse,
        onboardingData,
        redirectMode,
        handleChangeInviteStep,
        handleChangeVerifyResponse,
        handleChangOnboardingData,
        isIndividual,
      }}
    >
      <InviteSteps />
      <>{children}</>
    </AcceptVendorInviteContext.Provider>
  );
}

/**
 * @type {Object.<string, React.ReactNode>}
 */
const stepComponents = {
  step1: <Step1 />,
  step2: <Step2 />,
  step3: <Step3 />,
};

/**
 * @returns {React.ReactNode} The component corresponding to the current inviteStep value.
 */
function InviteSteps() {
  const { inviteStep } = useAcceptVendorInviteCtx();

  return (
    <>{stepComponents[inviteStep] || <Loader message="Please wait..." />}</>
  );
}

/**
 * @returns The context values of the AcceptVendorInviteContext.
 * @throws {Error} Throws an error if used outside the AcceptVendorInviteContext.
 */
export function useAcceptVendorInviteCtx() {
  const context = useContext(AcceptVendorInviteContext);
  if (!context) {
    throw new Error(
      "useAcceptVendorInviteCtx must be used within a AcceptVendorInviteContext",
    );
  }
  return context;
}
