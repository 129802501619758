import { createColumnHelper } from "@tanstack/react-table";

import {
  renderDate,
  renderFullName,
  renderJobLanguagePair,
  renderJobStatus,
  renderSecondsDuration,
  renderTime,
} from "@lango/common/helpers";

const columnHelper = createColumnHelper();

export const htJobColumns = [
  columnHelper.accessor("jobType.name", {
    id: "requestType",
    header: "Request Type",
  }),
  columnHelper.accessor("createdAt", {
    header: "Submitted Date",
    cell: (ctx) => renderDate(ctx.getValue()),
  }),
  columnHelper.accessor("jobStatus.name", {
    header: "Status",
    cell: (ctx) => renderJobStatus(ctx.getValue()),
  }),
];

export const onDemandJobColumns = [
  columnHelper.accessor("jobType.name", {
    id: "requestType",
    header: "Request Type",
  }),
  columnHelper.accessor("gender", {
    id: "genderName",
    header: "Gender",
    cell: ({ row: { original } }) => {
      const task = firstTask(original.tasks);
      return task.gender?.name;
    },
  }),
  columnHelper.accessor("assignedUser", {
    id: "createdByUser",
    cell: ({ row: { original } }) => {
      const task = firstTask(original.tasks);
      const assignedUser = task?.assignedUser;

      return renderFullName(assignedUser);
    },
    header: "Linguist",
  }),
  columnHelper.accessor("job", {
    cell: ({ row: { original } }) => {
      const languagePair = {
        languageFrom: original.languageFrom,
        languageTo: original.languageTo,
      }
      return renderJobLanguagePair(languagePair);
    },
    header: "Language Pair",
  }),
  columnHelper.accessor("activeCallDuration", {
    cell: ({ row: { original } }) => {
      // calculate duration
      const task = firstTask(original.tasks);

      return renderSecondsDuration(task.activeCallDuration);
    },
    header: "Call Duration",
  }),
  columnHelper.accessor("startTime", {
    header: "Start Time",
    id: "startTime",
    cell: ({ row: { original } }) => {
      const task = firstTask(original.tasks);
      return renderTime(task.startTime);
    },
  }),
  columnHelper.accessor("endTime", {
    header: "End Time",
    id: "endTime",
    cell: ({ row: { original } }) => {
      const task = firstTask(original.tasks);
      return renderTime(task.endTime);
    },
  }),
  columnHelper.accessor("createdAt", {
    id: "createdAt",
    cell: (info) => renderDate(info.getValue()),
    header: "Date",
  }),
];

function firstTask(tasks) {
  if (!Array.isArray(tasks) || tasks.length === 0) return null;
  return tasks[0];
}