import { gql } from "@apollo/client";

export const VERIFY_INVITATION = gql`
  query VerifyInvitation($input: VerifyInvitationRequest!) {
    verifyInvitation(input: $input) {
      firstName
      lastName
      email
      nextStep
      code
      type
      organizations {
        id
        name
      }
    }
  }
`;

export const ORG_CUSTOM_LOGIN_INFO = gql`
  query getOrgCustomLoginInfo(
    $orgSlugName: String!
    $applicationName: String!
  ) {
    getOrgCustomLoginInfo(
      orgSlugName: $orgSlugName
      applicationName: $applicationName
    ) {
      id
      name
      hasCustomLoginPage
      hasCustomLogo
      pageTitleEnabled
      logoURL
    }
  }
`;
