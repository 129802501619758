import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import environmentVars from "@lango/common/env";
import { VERIFY_INVITATION } from "@lango/common/queries";
import PageLoader from "@lango/common/components/page-loader";
import { useAcceptInvitation } from "@lango/common/hooks";

import { toastError } from "../alerts/functions/toast";
import { LinkExistingOrganizations } from "../AddClientAndVendorAdmin/linkOrganization/organizationList";

const { appName: applicationName } = environmentVars;

export default function VerifyInvitation({ type }) {
  const { code } = useParams();
  const navigate = useNavigate();
  const { handleAcceptInvitation, loading: acceptLoading } = useAcceptInvitation();
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();

  function handleError(err) {
    console.error(err);
    toastError(err?.message || err || "Failed to verify invitation");
    setTimeout(() => navigate("/"), 2000);
  }

  function handleVerificationCompleted({ verifyInvitation }) {
    const { email, nextStep, organizations } = verifyInvitation || {};

    if (isAuthenticated && user.email === email) {
      organizations?.length > 0
        ? console.debug("Choose organization")
        : handleAcceptInvitation(verifyInvitation);
      return;
    }

    if (isAuthenticated && user.email !== email) {
      toastError("Please log out to accept the invitation");
      navigate("/");
      return;
    }

    if (["login", "register"].includes(nextStep)) {
      const screen_hint = nextStep === "login" ? "login" : "signup";
      loginWithRedirect({
        appState: verifyInvitation,
        authorizationParams: {
          prompt: "login",
          screen_hint,
          login_hint: email,
        },
      }).catch(handleError);
      return;
    }
  }

  const { data, loading } = useQuery(VERIFY_INVITATION, {
    variables: { input: { code, type, applicationName } },
    context: { clientName: "public" },
    onCompleted: handleVerificationCompleted,
    onError: handleError,
  });

  const invitation = data?.verifyInvitation;
  const organizations = invitation?.organizations;
  const isLoading = acceptLoading || loading;

  return !isLoading && isAuthenticated && organizations?.length > 0 ? (
    <LinkExistingOrganizations
      organizations={organizations}
      handleSelect={(organizationID) =>
        handleAcceptInvitation({ ...invitation, organizationID })
      }
    />
  ) : (
    <PageLoader />
  );
}
