import classNames from "@lango/common/classnames";
import InputLabel, { renderError } from "@lango/common/features/forms/components/InputLabel";
import { useField } from "formik";
import { FieldIcon } from "./FieldIcon";

const TextInput = ({
  label = undefined,
  showLabel = undefined,
  extraClasses = undefined,
  size = undefined,
  infoText = undefined,
  readOnly = false,
  whiteBackground = false,
  containerClasses = undefined,
  icon = undefined,
  innerRef = undefined,
  trailingAddOn,
  trailingAddOnId,
  prefixValue = "",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const updatedValue = prefixValue && !inputValue.startsWith(prefixValue) ? prefixValue : inputValue;
    helpers.setValue(updatedValue);
  };

  let btnClass = classNames(
    "block w-full border border-gray-200 text-black focus:ring-gray-700 focus:border-gray-700 placeholder-gray-400",
    {
      "py-2 px-2 rounded": size === "small",
      "py-3 px-5 rounded-3xl": size !== "small",
      "bg-white": whiteBackground,
      "bg-gray-200 text-gray-600": size !== "small" && readOnly,
      "bg-gray-100": size !== "small" && !readOnly && !whiteBackground,
    },
    extraClasses,
    {
      "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
        meta.touched && meta.error,
    },
  );

  return (
    <div className={classNames("text-left", containerClasses)}>
      <div className="flex justify-between">
        {showLabel !== false && label ? (
          <InputLabel
            labelFor={props.id || props.name}
            text={label}
            infoText={infoText}
          />
        ) : null}
      </div>
      <div className="relative">
        <input
          ref={innerRef}
          type="text"
          className={btnClass}
          aria-describedby={props.errorMsgID}
          readOnly={readOnly}
          {...props}
          {...field}
          value={field?.value || prefixValue}
          onChange={handleChange}
        />
        {props.description && (
          <div className={"mt-2 text-sm text-gray-500"}>
            {props.description}
          </div>
        )}
        {trailingAddOn && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm" id={trailingAddOnId}>
              {trailingAddOn}
            </span>
          </div>
        )}
        <FieldIcon icon={icon} meta={meta} />
      </div>
      {renderError(meta)}
    </div>
  );
};

export default TextInput;
