import { createBrowserRouter, Navigate } from "react-router-dom";

import { FullPageError } from "@lango/common/components/errorDisplay";
import { NotificationsList } from "@lango/common/features/notifications";
import { VENDOR_ROUTES } from "@lango/common/routes";
import { AppContextProvider } from "@lango/common/app";
import AuthenticationGuard from "@lango/common/components/authentication-guard";
import FindUserContext from "@lango/common/context/findUserContext";
import { Onboarding } from "@lango/common/features/onboarding";
import VerifyInvitation from "@lango/common/features/verify-invitation";
import { PendingInvitationsScreen } from "@lango/common/features/pendingInvitations";

import AppContainer from "./appContainer";
import Home from "./pages/home";
import VendorJobDetails from "./pages/home/vendorJobDetails";
import Settings from "./pages/settings";

const vendorRoutes = [
  {
    path: "/requests/*",
    element: <Home />,
  },
  {
    path: "/settings/*",
    element: <Settings />,
  },
  {
    path: "/requests/job/:jobID/*",
    element: <VendorJobDetails />,
  },
  {
    path: "/notifications/*",
    element: <NotificationsList />,
  },
  {
    path: VENDOR_ROUTES.ONBOARDING,
    element: <Onboarding />,
  },
  {
    path: "*",
    element: <Navigate replace to={VENDOR_ROUTES.REQUESTS} />,
  },
  {
    index: true,
    element: <Navigate replace to={VENDOR_ROUTES.REQUESTS} />,
  },
];

export const routes = [
  {
    element: <AppContextProvider />,
    errorElement: <FullPageError />,
    children: [
      {
        element: <AppContainer />,
        children: [
          {
            element: <AuthenticationGuard />,
            children: [
              {
                element: <FindUserContext />,
                children: vendorRoutes,
              },
              {
                path: "/invitations",
                element: <PendingInvitationsScreen />,
              },
            ],
          },
          {
            path: "/invitation/vendor/:code",
            element: <VerifyInvitation type="vendor" />,
          },
        ],
      },
    ],
  },
];

export default createBrowserRouter(routes);
