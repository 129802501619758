import { InformationCircleIcon } from "@heroicons/react/solid";

import classNames from "@lango/common/classnames";

const NoticeBanner = ({
  title,
  message,
  extraClasses = "",
  dark = true,
  ...props
}) => {
  const classes = classNames(
    "mb-4 border-l-8 p-4 transition duration-300 ease-in-out flex",
    extraClasses,
    {
      "bg-lango-cyan border-lango-cyan-dark": dark,
      "bg-lango-light-blue border-lango-dark-blue": !dark,
    },
  );
  return (
    <div className={classes}>
      <div className="shrink-0">
        <InformationCircleIcon
          className={classNames("h-5 w-5", {
            "text-lango-cyan-dark": dark,
            "text-lango-dark-blue": !dark,
          })}
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <p className="text-sm text-black">
          {title && <span className={"font-bold"}>{title}:</span>}
          {message}
        </p>
      </div>
      {props.children}
    </div>
  );
};

export default NoticeBanner;
