import { useReactiveVar } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";

import { activeOrgVar } from "@lango/common/apollo";

export const WithOrganization = ({ children }) => {
  const org = useReactiveVar(activeOrgVar);
  const { isAuthenticated } = useAuth0();

  return isAuthenticated && org?.id > 0 && children;
};

export const WithAuthenticated = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated && children;
};
