import {
  ASSIGNED_USER_INFO,
  CALL_LOG,
  JOB_DETAILS,
  JOB_FORM_DATA,
  JOB_USER,
  LANGUAGE,
  LANGUAGE_FIELDS,
  PAGINATED_TASK_JOB,
} from "@lango/common/fragments";

import { gql } from "@apollo/client";

export const CANCEL_ON_DEMAND_TASK = gql`
  mutation cancelOnDemandTask($taskID: ID!) {
    cancelOnDemandTask(taskID: $taskID)
  }
`;

export const CREATE_HT_JOB = gql`
  mutation createHTJob($input: CreateHTRequest!) {
    createHTJob(input: $input)
  }
`;

export const HT_JOB_DATA = gql`
  ${JOB_USER}
  ${LANGUAGE}
  ${JOB_FORM_DATA}
  ${LANGUAGE_FIELDS}
  query GetJobData($jobID: ID!, $organizationID: ID!) {
    job(jobID: $jobID, organizationID: $organizationID) {
      id
      wordCount
      createdAt
      createdByUserID
      actualCompletionDate
      ...jobFormData
      ...LanguagesFields
      createdByUser {
        ...jobUser
      }
      sourceLanguages {
        ...BasicLanguageFields
      }
    }
  }
`;

// Job data for vendor
export const HT_VENDOR_JOB_DATA = gql`
  ${JOB_DETAILS}
  query GetJobData($jobID: ID!, $organizationID: ID!) {
    job(jobID: $jobID, organizationID: $organizationID) {
      id
      wordCount
      createdAt
      actualCompletionDate
      ...JobTypeAndStatus
    }
  }
`;

export const PAGINATED_HT_JOB_TASKS = gql`
  ${PAGINATED_TASK_JOB}
  query GetPaginatedJobTaskData(
    $jobID: ID!
    $organizationID: ID!
    $offset: Int!
    $pageLimit: Int!
    $sortBy: String
    $sortDir: SortDirection
    $filtersReq: FiltersRequest
  ) {
    paginatedTasksByJob(
      jobID: $jobID
      organizationID: $organizationID
      pagination: { offset: $offset, pageLimit: $pageLimit }
      sortBy: $sortBy
      sortDir: $sortDir
      filtersReq: $filtersReq
    ) {
      tasks {
        id
        createdAt
        wordCount
        sourceFileID
        sourceFile {
          id
          name
          size
        }
        ...paginatedTaskJob
      }
      totalRecords
    }
  }
`;

export const FIND_JOB_TYPE = gql`
  query GetJobType($jobID: ID!, $organizationID: ID!, $sourceAppName: String) {
    job(
      jobID: $jobID
      organizationID: $organizationID
      sourceAppName: $sourceAppName
    ) {
      id
      jobType {
        id
        type
      }
    }
  }
`;

export const HT_JOB_FORM_DATA = gql`
  query GetJobData(
    $organizationID: ID!
    $offset: Int!
    $pageLimit: Int!
    $sortBy: String
    $sortDir: SortDirection
    $sourceAppName: String!
    $editable: Boolean
  ) {
    allOrgProjects(
      sourceAppName: $sourceAppName
      organizationID: $organizationID
      pagination: { offset: $offset, pageLimit: $pageLimit }
      sortBy: $sortBy
      sortDir: $sortDir
      editable: $editable
    ) {
      projects {
        value: id
        label: projectName
      }
    }
    organizationLabels(organizationID: $organizationID) {
      label: name
      value: id
    }
  }
`;

export const GET_PAGINATED_LSP_JOBS = gql`
  ${JOB_USER}
  ${JOB_DETAILS}
  ${LANGUAGE}
  ${LANGUAGE_FIELDS}
  query getPaginatedLSPJobs($input: PaginatedJobsRequest!) {
    paginatedJobs(input: $input) {
      jobs {
        id
        ...JobTypeAndStatus
        ...LanguagesFields
        sourceLanguages {
          ...BasicLanguageFields
        }
        createdAt
        createdByUser {
          ...jobUser
        }
        organization {
          id
          name
        }
      }
      totalRecords
    }
  }
`;

export const GET_TRANSLATION_JOB_DATA = gql`
  ${JOB_USER}
  query GetTranslationJobData(
    $jobID: ID!
    $organizationID: ID!
    $sourceAppName: String
  ) {
    job(
      jobID: $jobID
      organizationID: $organizationID
      sourceAppName: $sourceAppName
    ) {
      id
      estimatedPrice
      requestedCompletionDate
      internalNotes
      customerNotes
      wordCount
      jobFiles {
        id
        name
        size
      }
      createdByUserID
      createdByUser {
        ...jobUser
      }
      labels {
        id
        label {
          label: name
          value: id
        }
      }
      jobType {
        id
        name
      }
      jobStatus {
        value: id
        label: name
      }
      languageFrom {
        value: id
        label: displayName
      }
      languageTo {
        value: id
        label: displayName
      }
      organization {
        id
        name
      }
      jobFiles {
        id
        name
        size
      }
    }
  }
`;

export const EDIT_JOB = gql`
  mutation editJob($input: EditJobRequest!) {
    editJob(input: $input)
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($jobIDs: [ID!]) {
    deleteJobs(jobIDs: $jobIDs)
  }
`;

export const GET_CALL_LOG = gql`
  ${CALL_LOG}
  query GetCallLog($jobID: ID!, $organizationID: ID!, $sourceAppName: String) {
    job(
      jobID: $jobID
      organizationID: $organizationID
      sourceAppName: $sourceAppName
    ) {
      tasks {
        callLog {
          ...callLog
        }
      }
    }
  }
`;

export const FIND_ON_DEMAND_JOB_DATA = gql`
  ${JOB_USER}
  query FetchOnDemandJobData(
    $jobID: ID!
    $organizationID: ID!
    $sourceAppName: String
  ) {
    job(
      jobID: $jobID
      organizationID: $organizationID
      sourceAppName: $sourceAppName
    ) {
      id
      estimatedPrice
      requestedCompletionDate
      internalNotes
      customerNotes
      createdAt
      calledNumber
      sourceType
      tasks {
        id
        servicedByCrowd
        startTime
        endTime
        gender {
          label: name
        }
        taskAssignedVendor {
          name
          profileType
          isLangoCrowdPartner
        }
      }
      createdByUser {
        ...jobUser
      }
      labels {
        id
        label {
          label: name
          value: id
        }
      }
      jobType {
        name
      }
      jobStatus {
        label: name
      }
      languageFrom {
        label: displayName
      }
      languageTo {
        label: displayName
      }
      organization {
        id
        name
      }
    }
  }
`;

// Would be cool to have this return only values with at least one matching record.
export const JOB_FILTER = gql`
  query JobFilter($types: [String!], $provider: String!) {
    allJobStatuses {
      value: id
      label: name
    }
    allJobTypes(types: $types) {
      value: id
      label: name
    }
    allProviderLanguages(provider: $provider) {
      value: id
      label: displayName
    }
  }
`;

export const TASK_FILTER = gql`
  query TaskFilter {
    allTaskStatuses {
      value: id
      label: name
    }
    allTaskTypes {
      value: id
      label: name
    }
  }
`;

export const EDIT_TASK = gql`
  mutation editTask($input: EditTaskRequest!) {
    editTask(input: $input)
  }
`;

export const JOB_STATUSES = gql`
  query JobStatuses {
    allJobStatuses {
      label: name
      value: id
    }
  }
`;

export const TASK_FEEDBACK = gql`
  query GetTaskFeedback(
    $jobID: ID!
    $organizationID: ID!
    $sourceAppName: String
  ) {
    job(
      jobID: $jobID
      organizationID: $organizationID
      sourceAppName: $sourceAppName
    ) {
      tasks {
        id
        taskFeedback {
          clientInterpreterRating
          clientCallQualityRating
          clientOtherFeedback
          vendorCallQualityRating
          vendorOtherFeedback
        }
      }
    }
  }
`;

export const HT_JOB_TASKS = gql`
  ${ASSIGNED_USER_INFO}
  query GetPaginatedJobTaskData(
    $jobID: ID!
    $organizationID: ID!
    $offset: Int!
    $pageLimit: Int!
    $sortBy: String
    $sortDir: SortDirection
    $sourceAppName: String
  ) {
    paginatedTasksByJob(
      jobID: $jobID
      organizationID: $organizationID
      pagination: { offset: $offset, pageLimit: $pageLimit }
      sortBy: $sortBy
      sortDir: $sortDir
      sourceAppName: $sourceAppName
    ) {
      tasks {
        id
        taskType {
          value: id
          label: name
        }
        vendorID
        vendor: vendorOrg {
          value: id
          label: name
        }
        sourceFileID
        rate
        targetDate
        sourceFile {
          id
          name
          size
        }
        taskFiles {
          id
          name
        }
        assignedUser {
          ...assignedUserInfo
        }
      }
      totalRecords
    }
  }
`;

export const GET_SIGNED_URL_FOR_VIDEO_RECORDING = gql`
  query GetSignedUrlForVideoRecording($taskID: ID!) {
    getSignedUrlForVideoRecording(taskID: $taskID)
  }
`;

export const GET_COMPOSITION_STATUS_FOR_VIDEO_RECORDING = gql`
  query GetCompositionStatusForVideoRecording($taskID: ID!) {
    getCompositionStatusForVideoRecording(taskID: $taskID)
  }
`;

// uploads

export const UPLOAD_JOB_FILES = gql`
  mutation uploadFile(
    $projectID: ID
    $organizationID: ID!
    $files: [Upload!]!
    $startingIndex: Int
    $uploadSessionID: String
  ) {
    jobFileUpload(
      projectID: $projectID
      organizationID: $organizationID
      files: $files
      startingIndex: $startingIndex
      uploadSessionID: $uploadSessionID
    ) {
      id
      files {
        id
        name
      }
    }
  }
`;

export const DELETE_FILES = gql`
  mutation deleteFiles($projectID: ID, $fileIDs: [ID!]) {
    deleteFiles(projectID: $projectID, fileIDs: $fileIDs)
  }
`;

export const CREATE_UPLOAD_SESSION = gql`
  mutation createUploadSession {
    createUploadSession
  }
`;

export const EXPORT_PROJECT_JOBS = gql`
  mutation exportProjectJobs(
    $request: ExportProjectJobsRequest!
    $filtersReq: FiltersRequest
  ) {
    exportProjectJobs(request: $request, filtersReq: $filtersReq) {
      totalJobs
      channelName
    }
  }
`;

export const FIND_LANGUAGES_BY_NAMES = gql`
  query FindLanguagesByJobTypes($jobTypes: [String], $orgID: ID!) {
    findLanguagesByJobTypes(jobTypes: $jobTypes, orgID: $orgID) {
      id
      value: name
      label: displayName
    }
  }
`;

export const FIND_RATES_UNITS_BY_NAMES = gql`
  query findRateUnitsByNames($organizationID: ID!, $names: [String!]!) {
    findRateUnitsByNames(organizationID: $organizationID, names: $names) {
      value: id
      label: name
    }
  }
`;

export const USER_NOTIFICATIONS = {
  GET: gql`
    query GetNotificationData(
      $offset: Int!
      $pageLimit: Int!
      $appName: String!
    ) {
      paginatedNotifications(
        pagination: { offset: $offset, pageLimit: $pageLimit }
        appName: $appName
      ) {
        notifications {
          id
          title
          description
          link
          createdAt
          isRead
          notificationType {
            id
            name
          }
        }
        totalRecords
      }
    }
  `,
  MARK_NOTIFICATIONS_READ: gql`
    mutation markNotificationsAsReadData {
      markNotificationsAsRead
    }
  `,
  MARK_NOTIFICATION_READ: gql`
    mutation markNotificationAsRead($id: ID!) {
      markNotificationAsRead(id: $id) {
        id
        isRead
      }
    }
  `,
  DELETE: gql`
    mutation DeleteNotification($id: ID!) {
      deleteNotification(id: $id)
    }
  `,
};
