export const RATE_UNITS = [
  { label: "Per Minute", display: "minute" },
  { label: "Per Hour", display: "hour" },
];

export const RATE_UNIT_LABELS = {
  PER_MINUTE: "Per Minute",
  PER_HOUR: "Per Hour",
};

export const PRO_RATA = "pro-rata";
export const ROUNDING = [
  { label: "Pro Rata", value: PRO_RATA },
  { label: "Up", value: "up" },
  { label: "Down", value: "down" },
];

export const ROUNDING_AMOUNT = [
  { label: "15", value: 15 },
  { label: "30", value: 30 },
];

export const EFFECTIVE_TIME_FRAME = [
  { label: "15", value: 15 },
  { label: "30", value: 30 },
  { label: "45", value: 45 },
];

export const RATE_SHEET_TYPE = {
  GENERAL: "General",
  SPECIFIC: "Specific",
};

export const isSpecificType = (_val) => _val === RATE_SHEET_TYPE.SPECIFIC;

export const RATE_ADJUSTMENTS = {
  PERCENTAGE_INCREASE: "Percentage increase to the base rate",
  FIXED_ADDITIONAL_RATE: "Fixed additional amount per job",
};

export const RATE_ADJUSTMENT_TYPES = [
  {
    label: RATE_ADJUSTMENTS.PERCENTAGE_INCREASE,
    value: RATE_ADJUSTMENTS.PERCENTAGE_INCREASE,
  },
  {
    label: RATE_ADJUSTMENTS.FIXED_ADDITIONAL_RATE,
    value: RATE_ADJUSTMENTS.FIXED_ADDITIONAL_RATE,
  },
];

export const ADDITIONAL_RATE_TYPES = {
  WEEKEND_RATE: {
    key: "weekendRate",
    label: "Weekend Rate",
  },
  HOLIDAY_RATE: {
    key: "holidayRate",
    label: "Holiday Rate",
  },
  AFTER_HOURS_RATE: {
    key: "afterHoursRate",
    label: "After Hours Rate",
  },
};
