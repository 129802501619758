import Dropdown from "@lango/common/components/dropdown";

import MenuItems from "./MenuItems";
import UserProfile from "./UserProfile";

const BaseUserMenu = ({ menuItems }) => {
  return (
    <Dropdown buttonClassName="ml-3">
      <Dropdown.Button className="inline-flex items-center">
        <UserProfile />
      </Dropdown.Button>
      <Dropdown.Menu>
        <MenuItems menuItems={menuItems} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BaseUserMenu;
