import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import env from "@lango/common/env";
import { ACCEPT_INVITATION } from "@lango/common/queries";
import { useAppState } from "@lango/common/context";
import { toastError } from "../features";

const { appName: applicationName } = env;

export function useAcceptInvitation() {
  const [mutate, { loading }] = useMutation(ACCEPT_INVITATION);
  const navigate = useNavigate();
  const { triggerFetchUser } = useAppState();

  async function handleAcceptInvitation({ code, type, organizationID }) {
    try {
      await mutate({
        variables: { input: { code, type, organizationID, applicationName } },
      });
      triggerFetchUser();
      navigate(type === "lsp" ? "/" : "/onboarding");
    } catch (error) {
      console.error(error);
      toastError(error?.message || "Failed to accept invitation");
      navigate("/");
    }
  }
  return { handleAcceptInvitation, loading };
}
