import classNames from "@lango/common/classnames";

/**
 * ProgressBar component to display a progress bar with customizable options.
 *
 * @param {Object} props - The properties object.
 * @param {number} [props.progress] - The initial progress value (0-100).
 * @param {string} [props.color] - The color of the progress bar. Options: "light-red", "green", "black", "medium-orange", "orange", "red".
 * @param {boolean} [props.isError] - Flag to indicate if the progress bar should display an error color.
 * @param {string} [props.extraClasses] - Additional CSS classes to apply to the progress bar.
 *
 * @returns {JSX.Element} The rendered ProgressBar component.
 */
const ProgressBar = ({ progress, color, isError, extraClasses }) => {
  const classes = classNames(
    "shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center transition-all ease-out duration-1000",
    {
      "bg-lango-error-red": color === "light-red",
      "bg-emerald-500": color === "green",
      "bg-black": color === "black",
      "bg-lango-medium-orange": !color || color === "medium-orange",
      "bg-lango-orange": !color || color === "orange",
      "bg-lango-error-red-dark": isError || color === "red",
    },
    extraClasses
  );
  return (
    <div className="flex h-4 flex-1 overflow-hidden rounded-full bg-gray-200 text-xs">
      <div style={{ width: progress + "%" }} className={classes}></div>
    </div>
  );
};
export default ProgressBar;
