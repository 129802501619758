import { useAuth0 } from "@auth0/auth0-react";
import { useReactiveVar } from "@apollo/client";
import { CogIcon, CollectionIcon, LogoutIcon } from "@heroicons/react/solid";
import { useState } from "react";

import { userOrgsVar } from "@lango/common/apollo/localState";
import OrganizationSwitcherModal from "@lango/common/components/OrganizationSwitcherModal";
import BaseUserMenu from "@lango/common/features/header/components/BaseUserMenu";
import { NotificationPanel } from "@lango/common/features/notifications";
import { useHasOrganizations } from "../../auth/hooks/useHasOrganizations";
import { WithOrganization } from "../../auth/components/UserAuthenticated";

const UserMenu = () => {
  const userOrgs = useReactiveVar(userOrgsVar);
  const [showOrgSwitcherModal, setOrgSwitcherShowModal] = useState(false);
  const { hasOrganizations } = useHasOrganizations();
  const { logout } = useAuth0();

  function handleShowOrgSwitcherModal() {
    setOrgSwitcherShowModal(true);
  }

  const switchCompanyItem =
    userOrgs?.length > 1
      ? {
          name: "Switch Company",
          onClick: handleShowOrgSwitcherModal,
          icon: CollectionIcon,
        }
      : null;

  const menuItems = [
    {
      name: "Settings",
      href: "/settings",
      icon: CogIcon,
    },
    switchCompanyItem,
    {
      name: "Log out",
      onClick: () => logout({ logoutParams: { returnTo: window.location.origin } }),
      icon: LogoutIcon,
    },
  ].filter(Boolean);

  const noOrgsItems = menuItems.filter((item) => item.name === "Log out");

  return (
    <>
      <WithOrganization>
        <NotificationPanel extraClasses="z-50" />
      </WithOrganization>
      <BaseUserMenu menuItems={hasOrganizations ? menuItems : noOrgsItems} />
      {showOrgSwitcherModal && (
        <OrganizationSwitcherModal
          isOpen={showOrgSwitcherModal}
          onRequestClose={() => {
            setOrgSwitcherShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default UserMenu;
