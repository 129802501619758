import { gql } from "@apollo/client";


export const CLIENT_USER_PHONE_NUMBER = {
  PAGINATED: gql`
    query GetPaginatedClientUserPhoneNumbers(
      $request: PaginatedClientUserRequest!
    ) {
      getPaginatedClientUserPhoneNumbers(request: $request) {
        clientUsers {
          user {
            id
            firstName
            lastName
          }
          id: userID
          phoneNumber
        }
        totalRecords
      }
    }
  `,
  ADD: gql`
    mutation addClientUserPhoneNumber($input: ClientUserPhoneNumberRequest!) {
      addClientUserPhoneNumber(input: $input)
    }
  `,
  UPDATE: gql`
    mutation updateClientUserPhoneNumber(
      $input: ClientUserPhoneNumberRequest!
    ) {
      updateClientUserPhoneNumber(input: $input) {
        id: userID
        phoneNumber
      }
    }
  `,
  DELETE: gql`
    mutation deleteClientUserPhoneNumber(
      $input: ClientUserPhoneNumberRequest!
    ) {
      deleteClientUserPhoneNumber(input: $input)
    }
  `,
  EXISTS: gql`
    query existClientUserPhoneNumber(
      $clientProfileID: ID!
      $userID: ID!
      $lspID: ID!
    ) {
      existClientUserPhoneNumber(
        clientProfileID: $clientProfileID
        userID: $userID
        lspID: $lspID
      )
    }
  `,
};
