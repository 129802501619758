import kebabcase from "lodash.kebabcase";
import { NavLink, Route, Routes } from "react-router-dom";

import Flex from "../tables/components/Flex";
import { primaryTabStyles } from "./styles";

export const Tabs = ({
  tabs,
  header = null,
  styles = primaryTabStyles,
  tabIcon = null,
  actions,
}) => {
  const updatedTabs = tabs
    .map((tab, i) => {
      if (!tab || !tab.label) {
        return null;
      }
      let path = tab.path;
      if (!path) {
        path = kebabcase(tab.label);
      }
      if (i === 0) {
        path = ".";
      }
      return { ...tab, path };
    })
    .filter(Boolean);
  const routes = [
    <Route key={"."} index element={updatedTabs[0].element} />,
    <Route key={"."} path={"*"} element={updatedTabs[0].element} />,
    ...updatedTabs.map(({ path, element }, i) => {
      if (i === 0) return;
      return <Route key={path} path={path} element={element} />;
    }),
  ].filter(Boolean);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {header}
        <TabActions actions={actions}>
          {updatedTabs.map(({ label, path, state }) => (
            <NavLink
              key={"link" + path}
              className={({ isActive }) =>
                isActive ? styles?.active : styles?.inactive
              }
              to={path}
              state={state}
              end
            >
              {label}
              {tabIcon}
            </NavLink>
          ))}
        </TabActions>
      </div>
      <div className={styles?.childContainer}>
        <Routes>{routes}</Routes>
      </div>
    </div>
  );
};

const TabActions = ({ actions, children }) => {
  if (!actions) return children;

  return (
    <Flex justify="between" align="center">
      <div>{children}</div>
      {actions}
    </Flex>
  );
};

export default Tabs;
