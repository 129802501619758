import { FaAngleRight } from "react-icons/fa";
import Flex from "../../tables/components/Flex";
import { Box, Container } from "@lango/common/components";
import Button from "../../forms/components/Button";

// todo: this component will be removed in client invitation ticket LGO-1351
const OrganizationList = ({ organizations, onClick }) => {
  return (
    <Box extraClasses="border-t">
      {organizations?.map((org, key) => (
        <Flex
          justify="between"
          key={key}
          extraClasses="p-5 border-b font-medium text-base leading-normal cursor-pointer hover:bg-gray-100"
          onClick={() => onClick(org?.id)}
        >
          {org?.name}
          <FaAngleRight />
        </Flex>
      ))}
    </Box>
  );
};

export function LinkExistingOrganizations({ organizations, handleSelect }) {
  return (
    <Container extraClasses="p-0">
      <Box extraClasses="p-9">
        <h2 className="pb-1 text-2xl font-bold text-black leading-normal">
          Select Organization
        </h2>
        <h1>Please select one of your organization.</h1>
      </Box>
      <OrganizationList organizations={organizations} onClick={handleSelect} />
      <Flex justify="center" extraClasses="">
        <Button
          textButton
          text="Add new organization"
          extraClasses="!pb-4 !pt-10"
          type="button"
          onClick={() => handleSelect()}
        />
      </Flex>
    </Container>
  );
}

export default OrganizationList;
