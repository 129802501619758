import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { USER_PROFILE_PICTURE } from "@lango/common/queries";
import { toastError, toastSuccess } from "../features";

export const useDeleteUserPicture = () => {
  const [deleteUserProfile, { loading }] = useMutation(
    USER_PROFILE_PICTURE.DELETE,
  );

  const handleDeletePicture = useCallback(async () => {
    try {
      await deleteUserProfile();
      toastSuccess("Picture picture deleted");
    } catch (error) {
      console.error(error);
      toastError("There was an error deleting the picture");
    }
  }, [deleteUserProfile]);

  return { handleDeletePicture, loading };
};
