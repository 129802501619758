import { useReactiveVar } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { activeOrgVar } from "@lango/common/apollo";
import LangoLogo from "@lango/common/components/langoLogo";
import HeaderContainer from "./HeaderContainer";
import HeaderLogo from "./HeaderLogo";

const BaseHeader = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const org = useReactiveVar(activeOrgVar);
  return (
    <HeaderContainer>
      {org?.id > 0 && isAuthenticated ? <HeaderLogo /> : <LangoLogo />}
      {isAuthenticated && children}
    </HeaderContainer>
  );
};

export default BaseHeader;
