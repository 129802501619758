import { useState } from "react";
import { useReactiveVar } from "@apollo/client";

import environmentVars from "@lango/common/env";
import { activeOrgVar } from "@lango/common/apollo/localState";
import {
  toastError,
  toastSuccess,
} from "@lango/common/features/alerts/functions/toast";

import useExportProjectJobs from "@lango/common/hooks/exportProjectJobs";

const useExportProjectJobProps = (exportType, projectID, filters) => {
  const [showJobExportModal, setShowJobExportModal] = useState(false);
  const [channel, setChannel] = useState();
  const org = useReactiveVar(activeOrgVar);
  const orgID = org?.id;

  const { handleExportProjectJob, loading } = useExportProjectJobs();
  const channelEvent = "org_jobs_export_" + orgID;

  const handleExportProjectJobResult = (result) => {
    const totalJobs = result?.totalJobs;
    const channel = result?.channelName;
    if (
      totalJobs <= environmentVars.ExportRowsThreshold &&
      channel?.length > 0
    ) {
      setShowJobExportModal(true);
      setChannel(channel);
    }

    if (totalJobs > environmentVars.ExportRowsThreshold) {
      toastSuccess("Export file will be sent as email attachment");
    }
  };

  const handleClose = () => {
    setShowJobExportModal(false);
    setChannel();
  };

  const handleErrorExportProjectJob = (err) => {
    toastError(err?.message);
    handleClose();
  };

  const handleClickExportJobButton = () => {
    handleExportProjectJob(
      projectID,
      orgID,
      exportType,
      filters,
      handleExportProjectJobResult,
      handleErrorExportProjectJob,
    );
  };

  return {
    showJobExportModal,
    channel,
    channelEvent,
    loading,
    handleClickExportJobButton,
    handleClose,
  };
};

export default useExportProjectJobProps;
