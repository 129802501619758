import { useAuth0 } from "@auth0/auth0-react";
import { useIdleTimer } from "react-idle-timer";

import envs from "@lango/common/env";

const msPerMinute = 1000 * 60;
const idleTimeoutMinutes = Number(envs.idleTimeoutMinutes);

const AutoLogout = () => {
  const { logout } = useAuth0();

  function handleOnIdle() {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  useIdleTimer({
    timeout: msPerMinute * idleTimeoutMinutes,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return <></>;
};

export default AutoLogout;
