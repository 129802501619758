import { Box } from "@lango/common/components";
import {
  isClientInvitation,
  isLSPInvitation,
} from "@lango/common/constants/invitationTypes";
import { Flex } from "@lango/common/features";
import { useNavigate } from "react-router-dom";

export default function PendingInvitationsList({ invitations }) {
  const navigate = useNavigate();
  return (
    <Box>
      {invitations.map((invitation, key) => (
        <Flex
          justify="between"
          key={key}
          extraClasses="p-5 border-b font-medium text-base leading-normal cursor-pointer hover:bg-gray-100"
          onClick={() => navigate(getInvitationLink(invitation))}
        >
          {invitation.profileName}
        </Flex>
      ))}
    </Box>
  );
}

function getInvitationLink(invitation) {
  const prefix = isLSPInvitation(invitation.invitationType)
    ? "lsp"
    : isClientInvitation(invitation.invitationType)
      ? "client"
      : "vendor";
  return `/invitation/${prefix}/${invitation.invitationCode}`;
}
