import ApolloProvider from "@lango/common/apollo/provider";
import Auth0Provider from "@lango/common/context/auth0";
import { ModalProvider, AppStateProvider } from "@lango/common/context";
import { Outlet } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import "react-dates/initialize";
import { HelmetProvider } from "react-helmet-async";

import "@lango/common/css/index.css";
import { initializeFullStory } from "@lango/common/helpers";
import { PusherProvider } from "../pusher";

import { combineComponents } from "../helpers";
import Container from "./container";

initializeFullStory();
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);

const defaultProviders = [
  Auth0Provider,
  Authenticator.Provider,
  ApolloProvider,
  PusherProvider,
  HelmetProvider,
  ModalProvider,
  Container,
  AppStateProvider,
];
export const AppContextProvider = ({ additionalProviders = [] }) => {
  const Combined = combineComponents(
    ...defaultProviders,
    ...additionalProviders,
  );
  return (
    <Combined>
      <Outlet />
    </Combined>
  );
};
