import { useReactiveVar } from "@apollo/client";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Avatar } from "flowbite-react";

import { activeOrgVar } from "@lango/common/apollo";
import { getFirstLastCharacters } from "@lango/common/helpers";
import { useFetchCurrentUser } from "@lango/common/hooks";
import { TrimmedText } from "@lango/common/components";

function renderOrganizationName(name) {
  return (
    <div className="hidden sm:block">
      {name || <span className="italic text-gray-500">No active company</span>}
    </div>
  );
}

function renderUserName(name) {
  return (
    <span className="hidden sm:inline-flex font-bold">
      <TrimmedText text={name} charLimit={20} />
      <ChevronDownIcon className="inline h-5 w-5" />
    </span>
  );
}

const UserProfile = () => {
  const org = useReactiveVar(activeOrgVar);
  const { currentUser, loading } = useFetchCurrentUser();
  const { firstName, lastName, picture } = currentUser || {};
  const { name } = org || {};

  const fullName = [firstName, lastName].filter(Boolean).join(" ");
  const shouldDisplayOrgName = name && !loading && name !== fullName;
  const props = picture?.url
    ? { img: picture.url, alt: "User Profile" }
    : fullName ? { placeholderInitials: getFirstLastCharacters(fullName) } : {};

  return (
    <Avatar rounded {...props}>
      <div className="text-sm text-left">
        {fullName && renderUserName(fullName)}
        {shouldDisplayOrgName && renderOrganizationName(name)}
      </div>
    </Avatar>
  );
};

function UserProfileWrapper() {
  const org = useReactiveVar(activeOrgVar);
  return org?.id > 0 ? <UserProfile /> : <Avatar rounded />;
}

export default UserProfileWrapper;
