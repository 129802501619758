import { VENDOR_RATE_SHEETS } from "../fragments";
import { gql } from "@apollo/client";

export const VENDOR_RATE_SHEET = {
  CREATE: gql`
    mutation createVendorRateSheet($input: CreateVendorRateSheetRequest!) {
      createVendorRateSheet(input: $input)
    }
  `,
  UPDATE: gql`
    mutation updateVendorRateSheet($input: UpdateVendorRateSheetRequest!) {
      updateVendorRateSheet(input: $input)
    }
  `,
  DELETE: gql`
    mutation deleteVendorRateSheet($organizationID: ID!, $rateSheetID: ID!) {
      deleteVendorRateSheet(
        organizationID: $organizationID
        rateSheetID: $rateSheetID
      )
    }
  `,
  FIND: gql`
    ${VENDOR_RATE_SHEETS}
    query findVendorRateSheet($orgID: ID!, $rateSheetID: ID!) {
      findVendorRateSheet(orgID: $orgID, rateSheetID: $rateSheetID) {
        ...VendorRateSheet
        rounding
        amount
        effectiveTimeTo
        rateSheetType
        additionalRates {
          additionalRateID
          rate
          rateAdjustmentType
          type
        }
        industries {
          value: id
          label: name
        }
        incrementalRates {
          incrementalRate
          effectiveTimeFrom
          effectiveTimeTo
        }
      }
    }
  `,
  PAGINATED: gql`
    ${VENDOR_RATE_SHEETS}
    query getPaginatedVendorRateSheets(
      $input: PaginatedRateSheetRequest!
      $filtersRequest: FiltersRequest
    ) {
      getPaginatedVendorRateSheets(
        input: $input
        filtersRequest: $filtersRequest
      ) {
        vendorRateSheets {
          ...VendorRateSheet
        }
        totalRecords
      }
    }
  `,
  VENDOR_LIST: gql`
    query getRateSheetVendorList(
      $request: RateSheetVendorRequest!
      $filtersRequest: FiltersRequest
    ) {
      getRateSheetVendorList(
        request: $request
        filtersRequest: $filtersRequest
      ) {
        rateSheetVendors {
          id: vendorProfileID
          name
          vendorProfileType
          status
        }
        totalRecords
      }
    }
  `,
  SYNC_VENDOR_PROFILES: gql`
    mutation syncVendorProfilesToRateSheet(
      $organizationID: ID!
      $rateSheetID: ID!
      $vendorIDs: [ID!]!
    ) {
      syncVendorProfilesToRateSheet(
        organizationID: $organizationID
        rateSheetID: $rateSheetID
        vendorIDs: $vendorIDs
      )
    }
  `,
  APPLIED_VENDOR_RATES: gql`
    ${VENDOR_RATE_SHEETS}
    query getPaginatedAppliedVendorRates($input: VendorRatesRequest!) {
      getPaginatedAppliedVendorRates(input: $input) {
        vendorRates {
          vendorProfileID
          status
          vendorRateSheet {
            ...VendorRateSheet
          }
        }
        totalRecords
      }
    }
  `,
  ELIGIBLE_VENDOR_RATES: gql`
    ${VENDOR_RATE_SHEETS}
    query getPaginatedEligibleVendorRates($input: VendorRatesRequest!) {
      getPaginatedEligibleVendorRates(input: $input) {
        vendorRateSheets {
          ...VendorRateSheet
        }
        totalRecords
      }
    }
  `,
  UPDATE_RATE_STATUS: gql`
    mutation updateVendorRateStatus($request: UpdateRateStatusRequest!) {
      updateVendorRateStatus(request: $request)
    }
  `,
};
