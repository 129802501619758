import { Outlet, useNavigate } from "react-router-dom";
import { activeOrgVar, appRedirectState, initializeUserData } from "../apollo";
import { useCallback, useEffect } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";

import NoticeBanner from "../features/alerts/components/NoticeBanner";
import PageLoader from "../components/page-loader";
import environmentVars from "../env";
import { useAppState } from "./appState";
import { FIND_CURRENT_USER, INITIALZE_USER } from "../queries";

const applicationName = environmentVars.appName
const FindUserContext = () => {
  const navigate = useNavigate();
  const { fetchUserTriggerCount } = useAppState();
  const org = useReactiveVar(activeOrgVar);
  const [mutation, { loading: initializing }] = useMutation(INITIALZE_USER);
  const [query, { loading }] = useLazyQuery(FIND_CURRENT_USER, {
    fetchPolicy: "network-only",
  });

  const handleFindUser = useCallback(async () => {
    await query({
      variables: { applicationName },
      onCompleted: ({ findCurrentUser }) => {
        initializeUserData(findCurrentUser);
        const org = findCurrentUser?.activeOrganization?.id;
        const hasInvitations = findCurrentUser?.pendingInvitations?.length;
        !org && hasInvitations && navigate("/invitations");
      },
    });
  }, [query]);

  const handleInitialize = useCallback(
    async (organizationID) => {
      try {
        await mutation({
          variables: { input: { applicationName, organizationID } },
        });
        await handleFindUser();
      } catch (error) {
        console.error(error);
      }
    },
    [mutation, handleFindUser],
  );

  useEffect(() => {
    const appState = appRedirectState();
    appRedirectState(null);
    appState?.initializeUser
      ? handleInitialize(appState?.organizationID)
      : handleFindUser();
  }, [fetchUserTriggerCount]);

  return loading || initializing ? (
    <PageLoader />
  ) : org?.id > 0 ? (
    <Outlet />
  ) : (
    <NoticeBanner
      title=""
      message="No active company. Please contact your language service provider"
      dark={false}
    />
  );
};

export default FindUserContext;
