import classNames from "@lango/common/classnames";
import { Flex } from "@lango/common/features";
import Button from "@lango/common/features/forms/components/Button";

const exportBtnClasses = (loading, extraClasses) => {
  return classNames(
    "black-button md:text-md flex py-1 px-3 text-sm font-medium focus:ring-black",
    extraClasses,
    {
      "cursor-not-allowed": loading,
    },
  );
};

const downloadBtnClasses =
  "black-button md:text-md mt-6 py-2 px-4 text-sm font-medium focus:ring-black";

const renderDownloadButton = (isFailed, onClick) => (
  <Flex justify="center">
    <Button
      className={downloadBtnClasses}
      disabled={isFailed}
      textButton={true}
      onClick={onClick}
      text="Download"
    />
  </Flex>
);

export { exportBtnClasses, renderDownloadButton};
