import * as yup from "yup";

import { PRO_RATA } from "../constants";

const duplicateLanguagePairs = "Language pairs cannot be duplicated";
const errTimeFrame = "End time must be greater than start time";
const errSameLanguages = "Source language shouldn't match the target language";

const uniqueLanguagePairs = function (languagePairs) {
  const seenPairs = new Set();
  for (let i = 0; i < languagePairs?.length; i++) {
    const { languageFrom, languageTo } = languagePairs[i];

    if (languageFrom && languageTo) {
      const pairKey = `${languageFrom.value}-${languageTo.value}`;
      if (seenPairs.has(pairKey)) {
        return this.createError({
          path: `languagePairs[${i}].languageTo`,
          message: "Duplicate language pairs are not allowed",
        });
      }
      seenPairs.add(pairKey);
    }
  }
  return true;
};

const validateRatePairs = function (incrementalRates) {
  const seenPairs = new Set();
  for (let i = 0; i < incrementalRates?.length; i++) {
    const { effectiveTimeFrom, effectiveTimeTo } = incrementalRates[i];

    if (effectiveTimeFrom && effectiveTimeTo) {
      const pairKey = `${effectiveTimeFrom.value}-${effectiveTimeTo.value}`;
      if (seenPairs.has(pairKey)) {
        return this.createError({
          path: `incrementalRates[${i}].effectiveTimeTo`,
          message: "Duplicate rate pairs are not allowed",
        });
      }
      seenPairs.add(pairKey);
    }
  }
  return true;
};


export const validateVendorRateSheet = yup.object().shape({
  name: yup.string().required("Rate name is required"),
  jobType: yup
    .object()
    .shape({ value: yup.string().required(), label: yup.string().required() })
    .nullable()
    .required("Job type is required"),
  multiLanguagePairs: yup.array().when("rateSheetType", {
    is: "Specific",
    then: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        }),
      )
      .required("At least one language pair is required")
      .min(1, "At least one language pair is required")
      .nullable(),
    otherwise: yup.array().nullable(),
  }),
  languagePairs: yup.array().when("rateSheetType", {
    is: "General",
    then: yup
      .array()
      .of(
        yup.object().shape({
          languageFrom: yup
            .object()
            .shape({
              value: yup.string().required(),
              label: yup.string().required(),
            })
            .nullable()
            .required("Source language is required"),
          languageTo: yup
            .object()
            .shape({
              value: yup.string().required(),
              label: yup.string().required(),
            })
            .nullable()
            .required("Target language is required")
            .test("unique-pair", errSameLanguages, function (languageTo) {
              const { languageFrom } = this.parent;
              if (!languageFrom || !languageTo) return true;
              return languageFrom?.value !== languageTo?.value;
            }),
        }),
      )
      .test("unique-pairs", duplicateLanguagePairs, uniqueLanguagePairs),
    otherwise: yup.array().nullable(),
  }),
  rate: yup
    .number()
    .typeError("Rate must be a number")
    .positive("Rate must be greater than zero")
    .required("Rate is required"),
  rateUnit: yup
    .object()
    .shape({ value: yup.string().required(), label: yup.string().required() })
    .nullable()
    .required("Unit is required"),
  rounding: yup
    .object()
    .shape({ value: yup.string().required(), label: yup.string().required() })
    .nullable()
    .required("Rounding is required"),
  amount: yup
    .object()
    .shape({ value: yup.string().required(), label: yup.string().required() })
    .nullable()
    .when("rounding.value", {
      is: PRO_RATA,
      then: yup.object().nullable(),
      otherwise: yup.object().nullable().required("Amount is required"),
    }),
  incrementalRates: yup
    .array()
    .of(
      yup.object().shape({
        incrementalRate: yup
          .number()
          .typeError("Incremental rate must be a number")
          .positive("Incremental rate must be greater than zero")
          .required("Incremental rate is required"),
        effectiveTimeFrom: yup
          .object()
          .shape({
            value: yup.number().required(),
            label: yup.string().required(),
          })
          .nullable()
          .required("Start time is required"),
        effectiveTimeTo: yup
          .object()
          .shape({
            value: yup.number().required(),
            label: yup.string().required(),
          })
          .nullable()
          .required("End time is required")
          .test("rate-pair", errTimeFrame, function (effectiveTimeTo) {
            const { effectiveTimeFrom } = this.parent;
            return effectiveTimeFrom?.value < effectiveTimeTo?.value;
          }),
      }),
    )
    .nullable()
    .test("rate-pairs", "", validateRatePairs),
  additionalRates: yup.object({
    weekendRate: yup
      .object()
      .shape({
        isEnabled: yup.boolean(),
        rate: yup.number().when("isEnabled", {
          is: true,
          then: yup
            .number()
            .typeError("Weekend rate must be a number")
            .positive("Weekend rate must be greater than zero")
            .required("Weekend rate is required"),
          otherwise: yup.number().nullable().notRequired(),
        }),
        rateAdjustmentType: yup.object().when("isEnabled", {
          is: true,
          then: yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Rate adjustment is required"),
          otherwise: yup.object().nullable().notRequired(),
        }),
      })
      .nullable(),
    holidayRate: yup
      .object()
      .shape({
        isEnabled: yup.boolean(),
        rate: yup.number().when("isEnabled", {
          is: true,
          then: yup
            .number()
            .typeError("Holiday rate must be a number")
            .positive("Holiday rate must be greater than zero")
            .required("Holiday rate is required"),
          otherwise: yup.number().nullable().notRequired(),
        }),
        rateAdjustmentType: yup.object().when("isEnabled", {
          is: true,
          then: yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Rate adjustment is required"),
          otherwise: yup.object().nullable().notRequired(),
        }),
      })
      .nullable(),
    afterHoursRate: yup
      .object()
      .shape({
        isEnabled: yup.boolean(),
        rate: yup.number().when("isEnabled", {
          is: true,
          then: yup
            .number()
            .typeError("After Hours rate must be a number")
            .positive("After Hours rate must be greater than zero")
            .required("After Hours rate is required"),
          otherwise: yup.number().nullable().notRequired(),
        }),
        rateAdjustmentType: yup.object().when("isEnabled", {
          is: true,
          then: yup
            .object()
            .shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
            .nullable()
            .required("Rate adjustment is required"),
          otherwise: yup.object().nullable().notRequired(),
        }),
      })
      .nullable(),
  }),
});

const isLanguageRequired = (language, otherLanguage) => {
  return !!language || !otherLanguage;
};

export const validateVendorRateSheetFilter = yup.object().shape({
  languageFrom: yup
    .object()
    .shape({ value: yup.string(), label: yup.string() })
    .nullable()
    .test(
      "when-required",
      "Source language is required",
      function (languageFrom) {
        const { languageTo } = this.parent;
        return isLanguageRequired(languageFrom, languageTo);
      },
    ),
  languageTo: yup
    .object()
    .shape({ value: yup.string(), label: yup.string() })
    .nullable()
    .test(
      "when-required",
      "Target language is required",
      function (languageTo) {
        const { languageFrom } = this.parent;
        return isLanguageRequired(languageTo, languageFrom);
      },
    )
    .test("unique-pair", errSameLanguages, function (languageTo) {
      const { languageFrom } = this.parent;
      return languageFrom && languageTo
        ? languageFrom.value !== languageTo.value
        : true;
    }),
});
