import className from "@lango/common/classnames";
import BaseHeader from "@lango/common/features/header/components/BaseHeader";

import UserMenu from "./UserMenu";
import { WithOrganization } from "../../auth/components/UserAuthenticated";

const Header = ({ children, extraClasses = "" }) => {
  return (
    <BaseHeader>
      <div
        className={className(
          "ml-2 flex flex-1 px-2 pl-2 text-sm sm:inset-0 md:ml-4 md:pl-4 md:text-base",
          extraClasses,
        )}
      >
        <WithOrganization>{children}</WithOrganization>
      </div>
      <div className="md:ml-4 md:block">
        <div className={"relative flex items-center md:ml-4"}>
          <UserMenu />
        </div>
      </div>
    </BaseHeader>
  );
};

export default Header;
