import useExportProjectJobProps from "./useExportProjectJobProps";
import ExportButton from "../export/ExportButton";
import ExportModal from "../export/ExportModal";

export const ExportProjectJobs = ({projectID,filters, ...props }) => {
  const {
    showJobExportModal,
    channel,
    channelEvent,
    loading,
    handleClickExportJobButton,
    handleClose,
  } = useExportProjectJobProps(props?.exportType,projectID, filters);

  return (
    <>
      <ExportButton
        text="Export Jobs"
        loading={loading}
        onClick={handleClickExportJobButton}
        extraClasses={props.className}
      />
      {showJobExportModal && (
        <ExportModal
          title="Export Jobs"
          isOpen={showJobExportModal}
          onClose={handleClose}
          channelName={channel}
          channelEvent={channelEvent}
        />
      )}
    </>
  );
};


