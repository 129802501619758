import { useMutation, useReactiveVar } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";

import { activeOrgVar } from "@lango/common/apollo/localState";
import { toastError } from "@lango/common/features";
import { UPDATE_CC_PERMISSIONS } from "@lango/common/queries";

const useUpdateCCPermissions = () => {
  const org = useReactiveVar(activeOrgVar);
  const { user } = useAuth0();
  const [updateCCPermissions, { loading }] = useMutation(UPDATE_CC_PERMISSIONS);

  const handleUpdateCCPermissions = async () => {
    try {
      await updateCCPermissions({
        variables: { input: { vendorID: org?.id, userIdentifier: user.sub } },
      });
    } catch (error) {
      toastError(error?.message || "Error checking permissions");
      console.error(error);
    }
  };

  return { handleUpdateCCPermissions, loading };
};

export default useUpdateCCPermissions;
