import { useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router";

import {
  activeOrgVar,
  updateActiveOrg,
  userOrgsVar,
} from "@lango/common/apollo/localState";
import classNames from "@lango/common/classnames";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import { useUpdateUserOrgLogin } from "@lango/common/features/auth/hooks";
import Loader from "@lango/common/features/forms/components/Loader";

import BaseModal from "./baseModal";

const getOrgInfoClasseName = (activeOrg, org) => {
  const isActive = activeOrg?.id === org?.id;
  return classNames(
    "truncate whitespace-nowrap overflow-hidden block pl-4 py-3 mx-2 mb-2 h-12 rounded-lg cursor-pointer",
    {
      "bg-black text-white": isActive,
      "hover:bg-lango-light-gray": !isActive,
    },
  );
};

const OrganizationList = ({ onRequestClose }) => {
  const userOrgs = useReactiveVar(userOrgsVar);
  const activeOrg = useReactiveVar(activeOrgVar);

  const navigate = useNavigate();
  const { handleUpdateUserOrgLogin, loading } = useUpdateUserOrgLogin();

  const handleChangeOrganization = async (organization) => {
    try {
      await handleUpdateUserOrgLogin(organization?.id);
      updateActiveOrg(organization);
      onRequestClose();
      navigate("/projects");
    } catch (error) {
      toastError("Error switching company");
      onRequestClose();
    }
  };

  return (
    <>
      {loading && <Loader />}
      {userOrgs?.map((organization) => (
        <span
          key={organization?.id}
          onClick={() =>
            !loading &&
            activeOrg?.id !== organization?.id &&
            handleChangeOrganization(organization)
          }
          className={getOrgInfoClasseName(activeOrg, organization)}
        >
          {organization.name}
        </span>
      ))}
    </>
  );
};

const OrganizationSwitcherModal = (props) => {
  return (
    <BaseModal title="Switch Company" {...props}>
      <OrganizationList {...props} />
    </BaseModal>
  );
};

export default OrganizationSwitcherModal;
