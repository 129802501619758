import { useLazyQuery, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { activeOrgVar } from "@lango/common/apollo";
import { ALL_VENDOR_PROFILE_LANGUAGE } from "@lango/common/queries";
import { prepareVendorProfileLanguageRequest } from "@lango/common/request";

export const useGetVendorProfileLanguage = ({ lspID, organizationID }) => {
  const [paginatedVendorProfileLanguage, { data, loading, error }] =
    useLazyQuery(ALL_VENDOR_PROFILE_LANGUAGE, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  const fetchVendorProfileLanguages = useCallback(
    (props) => {
      const request = prepareVendorProfileLanguageRequest({
        ...props,
        lspID,
        organizationID,
      });
      paginatedVendorProfileLanguage(request);
    },
    [paginatedVendorProfileLanguage, organizationID, lspID],
  );

  return {
    data: data?.paginatedVendorProfileLanguage?.vendorProfileLanguages || [],
    totalRecords: data?.paginatedVendorProfileLanguage?.totalRecords,
    fetchData: fetchVendorProfileLanguages,
    loading,
    error,
  };
};

export const useGetAllVendorProfileLanguages = (organizationID, canSkip) => {
  const { id: lspID } = activeOrgVar();
  const { loading, data, error } = useQuery(ALL_VENDOR_PROFILE_LANGUAGE, {
    variables: { offset: 0, pageLimit: 100, organizationID, lspID },
    skip: canSkip,
  });

  const { vendorJobTypes, jobTypesLookup } = prepareVendorLanguages(
    data?.paginatedVendorProfileLanguage?.vendorProfileLanguages,
  );

  const getVendorIndustries = (jobTypeValue) => {
    const jobType = jobTypesLookup[jobTypeValue];
    return jobType ? jobType.industries : [];
  };

  const getVendorLanguages = (jobTypeValue) => {
    const jobType = jobTypesLookup[jobTypeValue];
    return jobType ? jobType.languages : [];
  };

  return {
    loading,
    error,
    vendorJobTypes,
    getVendorIndustries,
    getVendorLanguages,
  };
};

/**
 * Prepares vendor languages data by organizing job types, industries, and language pairs.
 *
 * This function processes an array of vendor profile data to structure it into
 * a format that groups industries and language pairs by job types. It eliminates
 * duplicate industries and language pairs to ensure data integrity.
 *
 * @param {Array} data - An array of vendor profile language objects.
 * Each object should contain:
 *   - `jobType` (object with `label` and `value`)
 *   - `industry` (object with `label` and `value`, optional)
 *   - `languageFrom` (object with `label` and `value`, optional)
 *   - `languageTo` (object with `label` and `value`, optional)
 *
 * @returns {Object} An object with the following properties:
 *   - `vendorJobTypes` (Array): An array of job types, each with `label` and `value`.
 *   - `jobTypesLookup` (Object): A lookup table where the key is the job type value
 *     and the value contains:
 *     - `label` (string): The job type label.
 *     - `value` (string): The job type value.
 *     - `industries` (Array): A list of unique industries related to the job type.
 *     - `languages` (Array): A list of unique language pairs related to the job type.
 */
const prepareVendorLanguages = (data) => {
  const jobTypesMap = {};
  const vendorJobTypes = [];
  const jobTypesLookup = {};

  data?.forEach(({ jobType, industry, languageFrom, languageTo }) => {
    const jobTypeLabel = jobType?.label;
    const jobTypeValue = jobType?.value;
    if (!jobTypeLabel || !jobTypeValue) return;

    // add jobType to map if not already added
    if (!jobTypesMap[jobTypeValue]) {
      jobTypesMap[jobTypeValue] = {
        label: jobTypeLabel,
        value: jobTypeValue,
        industries: new Map(),
        languages: new Map(),
      };
      vendorJobTypes.push({ label: jobTypeLabel, value: jobTypeValue });
    }

    // add industry if available and not already added
    if (industry?.label && industry?.value) {
      const industryKey = industry.value;
      if (!jobTypesMap[jobTypeValue].industries.has(industryKey)) {
        jobTypesMap[jobTypeValue].industries.set(industryKey, {
          label: industry.label,
          value: industry.value,
        });
      }
    }

    // add language pair if both languageFrom and languageTo are present
    if (languageFrom && languageTo) {
      const languagePairKey = `${languageFrom.value}-${languageTo.value}`;
      if (!jobTypesMap[jobTypeValue].languages.has(languagePairKey)) {
        jobTypesMap[jobTypeValue].languages.set(languagePairKey, {
          label: `${languageFrom.label}-${languageTo.label}`,
          value: languagePairKey,
          pair: {
            languageFrom: { ...languageFrom },
            languageTo: { ...languageTo },
          },
        });
      }
    }

    // update jobTypesLookup with the current jobType
    jobTypesLookup[jobTypeValue] = {
      ...jobTypesMap[jobTypeValue],
      industries: Array.from(jobTypesMap[jobTypeValue].industries.values()),
      languages: Array.from(jobTypesMap[jobTypeValue].languages.values()),
    };
  });

  return { vendorJobTypes, jobTypesLookup };
};
