import {
  CLOUD_STORAGE_PROVIDER,
  GET_ORG_NOTICES,
  ORG_CLOUD_STORAGE_PROVIDER,
  USER_DETAILS,
} from "@lango/common/fragments";

import { gql } from "@apollo/client";

export const HAS_CUSTOM_LOGO = gql`
  query hasCustomLogo($organizationID: ID!) {
    hasCustomLogo(organizationID: $organizationID)
  }
`;

export const ORGANIZATION_LOGO_URL = gql`
  query getOrganizationLogoURL($organizationID: ID!) {
    getOrganizationLogoURL(organizationID: $organizationID)
  }
`;

export const ORG_SERVICE_LINES = gql`
  query orgServiceLines($organizationID: ID!) {
    findOrganizationJobTypes(organizationID: $organizationID) {
      id
      jobType {
        value: id
        label: name
        type
      }
    }
  }
`;

export const UPDATE_ORG_SERVICE_LINES = gql`
  mutation updateOrganizationJobTypes(
    $organizationID: ID!
    $jobTypeIDs: [ID!]
  ) {
    updateOrganizationJobTypes(
      organizationID: $organizationID
      jobTypeIDs: $jobTypeIDs
    )
  }
`;

export const ORGANIZATION_LSPS = gql`
  query findOrganizationLSPs($organizationID: ID!, $sourceAppName: String!) {
    findOrganizationLSPs(
      organizationID: $organizationID
      sourceAppName: $sourceAppName
    ) {
      id
      name
      organizationSettings {
        id
        twilioPhoneNumber
      }
    }
    getUserLastLoginUnderOrg(organizationID: $organizationID) {
      lastAccessedLsp {
        id
        name
      }
    }
  }
`;

export const ALL_ROLES = gql`
  query allRoles {
    allRoles {
      value: id
      label: name
    }
  }
`;

export const PAGINATED_ORG_USERS = gql`
  ${USER_DETAILS}
  query paginatedOrgUsers(
    $organizationID: ID!
    $inviteType: String
    $offset: Int!
    $pageLimit: Int!
    $sortBy: String
    $sortDir: SortDirection
  ) {
    paginatedUsersForOrganization(
      organizationID: $organizationID
      inviteType: $inviteType
      pagination: { offset: $offset, pageLimit: $pageLimit }
      sortBy: $sortBy
      sortDir: $sortDir
    ) {
      users {
        ...userDetails
        createdAt
        roles {
          id
          name
        }
        userOrgLastLogins {
          lastLoginAt
        }
      }
      totalRecords
    }
    canUpdateRoles(organizationID: $organizationID)
  }
`;

export const UPDATE_USER_ROLES = gql`
  mutation updateUserRoles(
    $userID: ID!
    $organizationID: ID!
    $roleIDs: [ID!]
  ) {
    updateUserRoles(
      userID: $userID
      organizationID: $organizationID
      roleIDs: $roleIDs
    )
  }
`;

export const FIND_CURRENT_USER = gql`
  query FindCurrentUser($applicationName: String!) {
    findCurrentUser {
      id
      firstName
      lastName
      email
      preferredTimezone
      notifications
      picture {
        url
      }
      gender {
        value: id
        label: name
      }
      activeOrganization(applicationName: $applicationName) {
        id
        name
        lastAccessedLsp
        organizationSettings {
          id
          pageTitleEnabled
        }
        lsps(applicationName: $applicationName) {
          id
          name
          organizationSettings {
            id
            twilioPhoneNumber
          }
        }
      }
      organizations(applicationName: $applicationName) {
        id
        name
        lastAccessedLsp
        organizationSettings {
          id
          pageTitleEnabled
        }
        lsps(applicationName: $applicationName) {
          id
          name
          organizationSettings {
            id
            twilioPhoneNumber
          }
        }
      }
      pendingInvitations(applicationName: $applicationName) {
        invitationCode
        profileName
        invitationType
      }
    }
  }
`;

export const UPDATE_CLIENT_WORD_COUNT_LIMIT = gql`
  mutation UpdateClientWordCountLimit(
    $clientProfileID: ID!
    $wordCountLimit: Int!
  ) {
    updateClientWordCountLimit(
      clientProfileID: $clientProfileID
      wordCountLimit: $wordCountLimit
    )
  }
`;

export const GET_CALL_RECORDING_MODES = gql`
  ${CLOUD_STORAGE_PROVIDER}
  ${ORG_CLOUD_STORAGE_PROVIDER}
  query GetCallRecordingModes($orgID: ID!, $lspID: ID!) {
    listCallRecordingModes {
      label: name
      value: id
    }
    organizationSettings(organizationID: $orgID) {
      callRecordingMode {
        label: name
        value: id
      }
      twilioPhoneNumber
    }
    clientProfileSettings(organizationID: $orgID, lspID: $lspID) {
      id
      isCallRecordingFeatureEnabled
    }
    findOrganizationCloudStorageProviders(organizationID: $orgID) {
      id
      ...orgCloudStorageProvider
      isDefault
    }
    listAllCloudStorageProviders {
      ...cloudStorageProviderField
    }
  }
`;

export const UPDATE_CALL_RECORDING_MODES = gql`
  mutation updateOrgCallRecordingMode(
    $organizationID: ID!
    $callRecordingModeID: Int!
    $cloudStorageProviders: [ID!]!
  ) {
    updateOrgCallRecordingMode(
      organizationID: $organizationID
      callRecordingModeID: $callRecordingModeID
    )
    updateOrganizationCloudStorageProviders(
      organizationID: $organizationID
      cloudStorageProviderIDs: $cloudStorageProviders
    )
  }
`;

export const CLIENT_MT_USAGE = gql`
  query getClientMTUsage($input: ClientMTWordCountRequest!) {
    getClientMTUsage(input: $input) {
      wordCount
      wordCountLimit
    }
  }
`;

export const GET_PAGINATED_JOB_ROUTINGS = gql`
  query GetPaginatedJobRoutings($input: PaginationJobRoutingRequest!) {
    getPaginatedJobRoutings(input: $input) {
      jobRoutings {
        id
        name
        jobType {
          label: name
          value: id
        }
        sourceLanguage: languageFrom {
          label: displayName
          value: id
          isoCodeLong
        }
        targetLanguage: languageTo {
          label: displayName
          value: id
          isoCodeLong
        }
        industry: industries {
          label: name
          value: id
        }
      }
      totalRecords
    }
  }
`;

export const CREATE_JOB_ROUTING = gql`
  mutation CreateJobRouting($input: CreateJobRoutingRequest!) {
    createJobRouting(input: $input)
  }
`;

export const UPDATE_JOB_ROUTING = gql`
  mutation updateJobRouting($input: UpdateJobRoutingRequest!) {
    updateJobRouting(input: $input)
  }
`;

export const DELETE_JOB_ROUTING = gql`
  mutation deleteJobRouting($jobRoutingID: ID!) {
    deleteJobRouting(jobRoutingID: $jobRoutingID)
  }
`;

export const NEW_CLIENT_PROFILE = gql`
  mutation CreateClientProfileRequest($name: String!, $lspID: ID!) {
    createClientProfile(name: $name, lspID: $lspID)
  }
`;

export const GET_ROUTINGS_LEVELS = gql`
  query GetRoutingLevels($jobRoutingID: ID!) {
    getRoutingLevels(jobRoutingID: $jobRoutingID) {
      id
      levelName
      levelRank
      createdAt
      updatedAt
      vendorProfiles {
        id
        name
        firstName
        lastName
        type
        rates {
          rate
          rateUnit {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_ROUTING_LEVEL = gql`
  mutation CreateRoutingLevel($input: CreateRoutingLevelRequest!) {
    createRoutingLevel(input: $input)
  }
`;

export const UPDATE_ROUTING_LEVEL = gql`
  mutation UpdateRoutingLevel($input: UpdateRoutingLevelRequest!) {
    updateRoutingLevel(input: $input)
  }
`;

export const PAGINATED_ORG_NOTICES = gql`
  ${GET_ORG_NOTICES}
  query paginatedNoticesByOrganization(
    $organizationID: ID!
    $offset: Int!
    $pageLimit: Int!
  ) {
    paginatedNoticesByOrganization(
      organizationID: $organizationID
      pagination: { offset: $offset, pageLimit: $pageLimit }
    ) {
      notices {
        ...getOrgNotices
      }
      totalRecords
    }
  }
`;

export const JOB_TYPE_LANGUAGES = gql`
  query languagesForJobType($jobTypeID: ID!) {
    languagesForJobType(jobTypeID: $jobTypeID) {
      value: id
      label: displayName
    }
  }
`;

export const JOB_TYPE_LANGUAGES_AND_LANGO_CROWD = gql`
  query languagesForJobType($jobTypeID: ID!, $organizationID: ID!) {
    languagesForJobType(jobTypeID: $jobTypeID) {
      value: id
      label: displayName
    }
    organizationSettings(organizationID: $organizationID) {
      useLangoCrowdOPIDefault
      useLangoCrowdVRIDefault
    }
  }
`;

export const ALL_JOB_TYPE_LANGUAGES = gql`
  query allJobTypeLanguages {
    allJobTypeLanguages {
      value: id
      label: displayName
    }
  }
`;

export const FETCH_JOB_TYPES = gql`
  query allJobTypes($types: [String!]) {
    allJobTypes(types: $types) {
      value: id
      label: name
    }
  }
`;

export const CREATE_NOTICE = gql`
  mutation createOrgNotice(
    $organizationID: ID!
    $noticeTypeID: ID!
    $message: String!
    $startTime: Time!
    $endTime: Time
  ) {
    createOrgNotice(
      organizationID: $organizationID
      noticeTypeID: $noticeTypeID
      message: $message
      startTime: $startTime
      endTime: $endTime
    ) {
      id
    }
  }
`;

export const UPDATE_LEVEL_RANKS = gql`
  mutation updateLevelRanks($input: [UpdateLevelRanksRequest!]!) {
    updateLevelRanks(input: $input)
  }
`;

// invitations

export const INVITE_VENDOR_USER = gql`
  mutation inviteVendorUser($input: InviteVendorUserRequest!) {
    inviteVendorUser(input: $input)
  }
`;

// vendor invitations

export const RESEND_VENDOR_INVITATION = gql`
  mutation resendVendorInvitation($code: String!) {
    resendVendorInvitation(code: $code)
  }
`;

export const GET_PAGINATED_VENDOR_INVITATIONS = gql`
  query PaginatedVendorInvitations($input: VendorInvitationRequest!) {
    paginatedVendorInvitations(input: $input) {
      vendorInvitations {
        invitationCode
        email
        isAccepted
      }
      totalRecords
    }
  }
`;

export const UPDATE_LANGO_CROWD_SETTINGS = gql`
  mutation UpdateLangoCrowdSettings(
    $input: UpdateOrganizationSettingsRequest!
  ) {
    updateOrganizationSettings(input: $input)
  }
`;

export const GET_LANGO_CROWD_SETTINGS = gql`
  query getLangoCrowdSettings($organizationID: ID!) {
    organizationSettings(organizationID: $organizationID) {
      useLangoCrowdOPIDefault
      useLangoCrowdVRIDefault
    }
  }
`;

export const INVITE_CLIENT_USER = gql`
  mutation inviteClientUser($input: InviteClientUserRequest!) {
    inviteClientUser(input: $input)
  }
`;

// client invitations

export const RESEND_CLIENT_INVITATION = gql`
  mutation resendClientInvitation($code: String!) {
    resendClientInvitation(code: $code)
  }
`;

export const GET_PAGINATED_CLIENT_INVITATIONS = gql`
  query PaginatedClientInvitations($input: PaginatedClientInvitationsRequest!) {
    paginatedClientInvitations(input: $input) {
      clientInvitations {
        id
        invitationCode
        email
        isAccepted
      }
      totalRecords
    }
  }
`;

export const CLIENT_ONBOARD = {
  STEP1: gql`
    mutation clientOnboardStep1($input: ClientOnboardStep1Request!) {
      clientOnboardStep1(input: $input)
    }
  `,
  STEP2: gql`
    mutation clientOnboardStep2($input: ClientOnboardStep2Request!) {
      clientOnboardStep2(input: $input)
    }
  `,
  STEP3: gql`
    mutation clientOnboardStep3($input: ClientOnboardStep3Request!) {
      clientOnboardStep3(input: $input)
    }
  `,
  STEP4: gql`
    mutation clientOnboardStep4($input: ClientOnboardStep4Request!) {
      clientOnboardStep4(input: $input)
    }
  `,
};

export const CLIENT_ONBOARDING_STATUS = gql`
  query clientOnboardingStatus($orgID: ID!, $lspID: ID!) {
    clientOnboardingStatus(orgID: $orgID, lspID: $lspID) {
      clientProfileID
      profileName
      percentage
      nextStep
    }
  }
`;

export const UPDATE_CLIENT_ONBOARDING_STATUS = gql`
  mutation updateClientOnboardingStatus(
    $input: UpdateClientOnboardingStatusRequest!
  ) {
    updateClientOnboardingStatus(input: $input)
  }
`;

// org invitations

export const INVITE_ORG_USER = gql`
  mutation inviteOrgUser($input: InviteOrgUserRequest!) {
    inviteOrgUser(input: $input)
  }
`;

export const GET_PAGINATED_ORG_INVITATIONS = gql`
  query PaginatedOrgInvitations($input: PaginatedOrgInvitationsRequest!) {
    paginatedOrgInvitations(input: $input) {
      orgInvitations {
        id
        email
        invitationCode
        isAccepted
      }
      totalRecords
    }
  }
`;

export const ORGANIZATION_NOTIFICATION = {
  READ: gql`
    mutation readOrgNotification($notificationID: Int!) {
      readOrgNotification(notificationID: $notificationID) {
        id
        isRead
      }
    }
  `,
  READ_ALL: gql`
    mutation readAllOrgNotifications($orgID: ID!) {
      readAllOrgNotifications(orgID: $orgID)
    }
  `,
  PAGINATED: gql`
    query GetPaginatedOrgNotifications($input: OrgNotificationRequest!) {
      getPaginatedOrgNotifications(input: $input) {
        organizationNotifications {
          id
          title
          description
          createdAt
          isRead
          organizationID
          sentByID
          sentBy {
            name
          }
          notificationType {
            id
            name
          }
        }
        totalRecords
      }
    }
  `,
};

export const RESEND_ORG_INVITATION = gql`
  mutation resendOrgInvitation($code: String!) {
    resendOrgInvitation(code: $code)
  }
`;

export const FETCH_VENDOR_PROFILE_QUALIFICATION = gql`
  query fetchVendorProfileQualification($orgID: ID!) {
    fetchVendorProfileQualification(orgID: $orgID) {
      id
      firstName
      lastName
      vendorProfileLanguages {
        id
        languageFrom {
          label: displayName
          value: id
        }
        languageTo {
          label: displayName
          value: id
        }
        industry {
          label: name
          value: id
        }
        jobType {
          label: name
          value: id
          type
        }
        qualificationStatus
      }
    }
  }
`;

export const CLIENT_PROFILE_VRI_SETTINGS = gql`
  query clientProfileVRISettings($clientProfileID: ID!) {
    clientProfileVRISettings(clientProfileID: $clientProfileID) {
      enableScreenShare
      enableVirtualBackground
      jobWordCountLimitEnabled
      automaticallyDeleteDocuments
      glossarySupport
      isScreenShareFeatureEnabled
      isVirtualBackgroundFeatureEnabled
      isJobWordCountLimitFeatureEnabled
      isGlossarySupportFeatureEnabled
      isAutomaticallyDeleteDocumentsFeatureEnabled
    }
  }
`;

export const GET_ORGANIZATION_ON_DEMAND_SETTINGS = gql`
  query getOrganziationOnDemandSettings($organizationID: ID!) {
    organizationSettings(organizationID: $organizationID) {
      enableScreenShare
      enableVirtualBackground
      twilioPhoneNumber
    }
  }
`;

export const UPDATE_ORGANIZATION_VRI_SETTINGS = gql`
  mutation UpdateOrganizationVRISettings(
    $input: UpdateOrganizationSettingsRequest!
  ) {
    updateOrganizationSettings(input: $input)
  }
`;

export const GET_CLIENT_PROFILE_ID = gql`
  query GetClientProfileID($orgID: ID!, $lspID: ID!) {
    clientProfileSettings(organizationID: $orgID, lspID: $lspID) {
      id
    }
  }
`;

export const FETCH_CURRENCIES = gql`
  query allCurrencies {
    allCurrencies {
      id
      name
    }
  }
`;

export const CLIENT_CONTACTS = {
  CREATE: gql`
    mutation createClientContact($input: ClientContactRequest!) {
      createClientContact(input: $input) {
        id
      }
    }
  `,
  UPDATE: gql`
    mutation updateClientContact($input: ClientContactRequest!) {
      updateClientContact(input: $input) {
        id
      }
    }
  `,
  DELETE: gql`
    mutation deleteClientContact($id: ID!) {
      deleteClientContact(id: $id)
    }
  `,
  MARK_AS_FAVORITE: gql`
    mutation markClientContactAsFavorite($id: ID!) {
      markClientContactAsFavorite(id: $id)
    }
  `,
  PAGINATED: gql`
    query paginatedOrgClientContacts(
      $organizationID: ID!
      $lspID: ID!
      $sortBy: String
      $sortDir: SortDirection
      $offset: Int!
      $pageLimit: Int!
    ) {
      paginatedOrgClientContacts(
        organizationID: $organizationID
        lspID: $lspID
        sortBy: $sortBy
        sortDir: $sortDir
        pagination: { offset: $offset, pageLimit: $pageLimit }
      ) {
        clientContacts {
          id
          organizationID
          lspID
          name
          firstName
          lastName
          email
          phone
          website
          title
          isFavorite
          country
          address1
          address2
          city
          postalCode
        }
        totalRecords
      }
    }
  `,
};

export const GET_USERS_FOR_ORGANIZATION = gql`
  query usersForOrganization($organizationID: ID!) {
    usersForOrganization(organizationID: $organizationID) {
      id
      firstName
      lastName
    }
  }
`;
