import { gql } from "@apollo/client";

export const JOB_TYPE = gql`
  fragment JobType on JobType {
    value: id
    label: name
  }
`;

export const RATE_UNIT = gql`
  fragment RateUnit on RateUnit {
    value: id
    label: name
  }
`;

export const LANGUAGE_PAIRS = gql`
  fragment languagePairs on VendorRateSheetLanguagePair {
    languageFrom {
      value: id
      label: displayName
    }
    languageTo {
      value: id
      label: displayName
    }
  }
`;

export const VENDOR_RATE_SHEETS = gql`
  ${JOB_TYPE}
  ${RATE_UNIT}
  ${LANGUAGE_PAIRS}
  fragment VendorRateSheet on VendorRateSheet {
    id
    name
    rate
    description
    rateSheetType
    jobType {
      ...JobType
    }
    rateUnit {
      ...RateUnit
    }
    languagePairs {
      ...languagePairs
    }
  }
`;
