import { useField, useFormikContext } from "formik";

import { ExclamationCircleIcon } from "@heroicons/react/solid";
import Flex from "@lango/common/features/tables/components/Flex";
import InputLabel from "@lango/common/features/forms/components/InputLabel";
import classNames from "@lango/common/classnames";

const RateInput = ({
  className,
  label,
  showLabel,
  extraClasses,
  size,
  infoText,
  options,
  currencySymbol,
  currencyName,
  icon,
  type,
  whiteBackground,
  addOnText,
  addOnTextIcon,
  formateValue = false,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField({ ...props, name: props.name });

  const formatValueOnBlur = () => {
    const value = parseFloat(field.value);
    if (!isNaN(value)) {
      helpers.setValue(value.toFixed(2));
    }
  };

  const handleBlur = formateValue ? formatValueOnBlur : field.onBlur;

  function renderOptionsAsName(options) {
    return options.map((option) => (
      <option key={option.id} value={option.id}>
        {option.name}
      </option>
    ));
  }

  let btnClass = classNames(
    "flex items-center w-full pl-7 border border-gray-200 text-black focus:ring-gray-700 focus:border-gray-700 placeholder-gray-400",
    extraClasses,
    {
      "py-2 px-8 rounded": size === "small",
      "py-1 bg-gray-100 rounded-full": size !== "small",
      "bg-white": whiteBackground,
    },
    {
      "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
        meta.touched && meta.error,
    },
  );

  return (
    <div className={classNames("text-left", className)}>
      {showLabel !== false && (
        <InputLabel
          labelFor={props.id || props.name}
          text={label}
          infoText={infoText}
        />
      )}
      <div className={btnClass}>
        <span>{currencySymbol || "$"}</span>
        <input
          type={type}
          className="w-full focus:outline-none focus:ring-0 rounded-full border-none bg-transparent"
          aria-describedby={props.errorMsgID}
          {...field}
          {...props}
          onBlur={handleBlur}
        />
        <div className="pointer-events-none inset-y-0 right-0 flex items-center pr-3">
          {meta.touched && meta.error ? (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          ) : (
            icon
          )}
        </div>
        {options?.length > 0 && (
          <>
            <div className="relative">
              <div className="absolute h-12 w-px bg-gray-300 -translate-y-6"></div>
            </div>
            <div className="flex items-center">
              <label htmlFor="currency" className="sr-only">
                Currency
              </label>
              <select
                id="currency"
                name={currencyName || "currency"}
                className="h-full w-32 rounded-full gap-5 border-none bg-transparent text-gray-500 focus:outline-none focus:ring-0 sm:text-sm"
                defaultValue={props.defaultOption}
                onChange={({ target }) => {
                  setFieldValue(currencyName || "currency", target.value);
                }}
                disabled={props.disabled}
              >
                {renderOptionsAsName(options)}
              </select>
            </div>
          </>
        )}
        {addOnText && (
          <div>
            <div className="relative">
              <div className="absolute h-12 w-px bg-gray-300 -translate-y-3.5"></div>
            </div>
            <Flex
              align="center"
              justify="between"
              extraClasses="text-black sm:text-sm gap-12 mr-4 whitespace-nowrap"
            >
              <p className="text-black sm:text-sm mx-5 ">{addOnText}</p>
              {addOnTextIcon}
            </Flex>
          </div>
        )}
      </div>
      {meta.touched && meta.error && (
        <p className="ml-3 mt-2 h-1 text-sm text-red-600" id={props.errorMsgID}>
          {meta.error}
        </p>
      )}
    </div>
  );
};

RateInput.defaultProps = {
  infoText: null,
  defaultcurrency: "USD",
  type: "text",
};

export default RateInput;
