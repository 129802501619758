import PropTypes from "prop-types";
import classNames from "@lango/common/classnames";

/**
 * BasePageHeading component that renders a heading with optional extra classes.
 *
 * @param {Object} props - The props for the BasePageHeading component.
 * @param {string} props.heading - The heading text for the component.
 * @param {string} [props.name] - The name text for the component.
 * @param {string} [props.description] - The description text.
 * @param {string} [props.extraClasses] - Additional CSS classes to apply to the component.
 * @param {string} [props.descriptionClasses] - Additional CSS classes to apply to the description.
 * @param {boolean} [props.required] - Whether to show the required asterisk.
 * @returns {React.ReactNode} The rendered BasePageHeading component.
 */
const BasePageHeading = ({
  heading,
  name,
  description,
  extraClasses,
  descriptionClasses,
  required = false,
  ...rest
}) => {
  const requiredMarkup = required && (
    <>
      <span className="text-sm text-red-700 ml-0.5">*</span>
      <span className="text-sm italic text-gray-500"> Required</span>
    </>
  );

  return (
    <>
      <h1
        className={classNames(
          "text-left text-base md:text-2xl font-bold leading-tight text-black",
          extraClasses,
        )}
        {...rest}
      >
        {heading}
        <span className="font-normal">{name}</span>
        {requiredMarkup}
      </h1>
      {description && <p className={descriptionClasses}>{description}</p>}
    </>
  );
};

export default BasePageHeading;
