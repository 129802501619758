import { createContext, useState, useContext } from "react";

// Initial state
const initialState = {
  fetchUserTriggerCount: 0,
  triggerFetchUser: () => {},
};

// Create context
const AppStateContext = createContext(initialState);

// Provider component
// Use reducer hook!
export const AppStateProvider = ({ children }) => {
  const [fetchUserTriggerCount, setFetchUserTriggerCount] = useState(
    initialState.fetchUserTrigger,
  );

  function triggerFetchUser() {
    setFetchUserTriggerCount((prev) => prev + 1);
  }

  return (
    <AppStateContext.Provider
      value={{ fetchUserTriggerCount, triggerFetchUser }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
};
