import { Box, Container } from "@lango/common/components";
import { useFetchCurrentUser } from "@lango/common/hooks";
import PendingInvitationsList from "./PendingInvitationsList";

export const PendingInvitationsScreen = () => {
  const { currentUser } = useFetchCurrentUser();
  const invitations = currentUser?.pendingInvitations;

  return (
    <Container extraClasses="p-0">
      <Box extraClasses="p-9">
        <h2 className="pb-1 text-2xl font-bold text-black leading-normal text-center">
          Pending Invitations
        </h2>
      </Box>
      {invitations?.length > 0 && <PendingInvitationsList invitations={invitations} />}
    </Container>
  );
};
