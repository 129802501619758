import React from "react";

import Abbreviation from "./Abbreviation";

const TrimmedText = ({ text, charLimit = 10 }) => {
  if (typeof text !== "string" || text.length <= charLimit) {
    return text;
  }
  const ellipsis = "...";
  const trimmed = text.slice(0, charLimit - 3) + ellipsis;
  return <Abbreviation short={trimmed} long={text} />;
};

export default TrimmedText;
